import React from "react";
import logo from "../../assets/img/logo.jpeg";
import './footer.css'
import {
  CDBFooter,
  CDBBtn,
  CDBIcon,
  CDBBox,
} from "cdbreact";

function Footer() {
  return (
    <CDBFooter className="shadow">
    <CDBBox
      display="flex"
      justifyContent="between"
      alignItems="center"
      className="mx-auto py-4 flex-wrap"
    >
      <CDBBox display="flex" alignItems="center">
        <a href="/" className="LinkMS">
          <img
            alt="logo"
            src={logo}
            width="30px"
          />
        </a>
      </CDBBox>
      <CDBBox>
        <small className="ml-2"> &copy; M&S Consultant SAS</small>
      </CDBBox>
      <CDBBox display="flex">
        <CDBBtn flat color="dark" className="p-2">
          <CDBIcon fab icon="facebook-f" />
        </CDBBtn>
        <CDBBtn flat color="dark" className="mx-3 p-2">
          <CDBIcon fab icon="twitter" />
        </CDBBtn>
        <CDBBtn flat color="dark" className="p-2">
          <CDBIcon fab icon="instagram" />
        </CDBBtn>
      </CDBBox>
    </CDBBox>
  </CDBFooter>
  );
}

export default Footer;
