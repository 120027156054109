import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddImageServices from "../../ui/AddImageServices/AddImageServices";
import './AddNewTour.css';
import { addBookingTour } from "msbookingapiclient";
import Swal from "sweetalert2";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function AddNewTour(props) {
  const dispatch = useDispatch();
  const [countAdults, setCountAdults] = useState(0);
  const [countChildren, setCountChildren] = useState(0);
  const [files, setFiles] = useState([]);
  const [newTourBooking, setNewTourBooking] = useState({
    name: "",
    capacity: 0,
    price: 0,
    description: "",
    status: true,
    preferenceReservation: "Turismo",

  })
  const saveBooking = () => {
    Swal.fire({
      title: '¿Esta seguro de crear un nuevo tour?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Crear tour'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true));
        addBookingTour(props.booking_Id, newTourBooking, files).then(result => {
          props.bookingList.push(newTourBooking);
          dispatch(showLoading(true))
          Swal.fire('Ha creado una nueva habitación');      
          props.onShow(false);            
        })
      } else {
        return
      }
    })
  }
    const inputChangeListener = (ev) => {
      switch (ev.target.name) {
        case "name":
          newTourBooking.name = ev.target.value;
          break;
        case "description":
          newTourBooking.description = ev.target.value;
          break;
        case "capacity":
          newTourBooking.capacity = ev.target.value;
          break;
        case "price":
          newTourBooking.price = ev.target.value;
          break;



      }
    }
    return (
      <>
        <Modal
          show={props.show}
          onHide={() => props.onShow(false)}
          dialogClassName="modal-90w "
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Añadir Tour</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='Form'>
              <div className="divFormEdit">
                <input id="name" type="text" name="name" onChange={inputChangeListener} className="standard-input" placeholder="Nombre" />
              </div>

              <br />
              <div className="divFormEdit">
                <input id="name" type="text" name="description" onChange={inputChangeListener} className="standard-input" placeholder="Descripción" />
              </div><br />

              <label>Capacidad:</label>

              <Button
                style={{ marginLeft: "22px" }}
                onClick={() => setCountChildren(countChildren + 1)}
              >
                +
              </Button>
              <label
                name="capacity"
                onChange={inputChangeListener}
                style={{ marginLeft: "10px" }}
              >
                {" "}
                {countChildren}{" "}
              </label>

              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => setCountChildren(countChildren - 1)}
              >
                -
              </Button>
              <br />
              <br />
              <div className="divFormEdit">
                <input id="name" type="number" name="price" onChange={inputChangeListener} className="standard-input" placeholder="Precio" />
              </div><br />

              <p>Por favor añada 1 foto del tour</p>

              <AddImageServices id={0} typeAsset={"ImagesToBookingHotels"} files={files}></AddImageServices>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={saveBooking} variant="primary">Guardar</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default AddNewTour