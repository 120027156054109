import React from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';
import './BuceoServices.css';
import CarouselImages from "../carousel/CarouselRoom";
import Label from "../../ui/Label";

function CardBuseo(props) {
  const open = () => {
    props.onShow(true);
  };


  return (
    <>
      <Container fluid>
        <Row>
            <div className="">
              <Card.Text>Descriptión: <br/>{props.Buseo.description}</Card.Text>
            </div>
            <div className="">
              <h4>Característica</h4>
              <label>Capacidad: {props.Buseo.capacity}</label>
            </div>
        </Row>
      </Container>
    </>
  )
}

export default CardBuseo