import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import Swal from "sweetalert2";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputDays from "../ui/InputDaysConfiguration/InputDays";
import BuceoServices from "../components/BuceoServices/BuceoServices";
import Maps from "../ui/Maps/Maps";
import {
  getBusinessById,
  updateMicroSite,
  getMicroSiteById,
} from "msbookingapiclient";
import { updateBookingHeader } from "msbookingapiclient/src/Booking";
import InputDescription from "../components/InputDescription/InputDescription";
import RedesSociales from "../components/ConfiguracionRedesSociales/RedesSociales";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import CarouselBanner from "../ui/CarouselMicrosite/CarouselBanner";
import BusinessServicesList from "../ui/Services/BusinessServicesList";
import MenuBasic from "../components/Menu/MenuBasic";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../store/slices/Loader/loadingReducer";
//redux<

function ConfiguracionesReservaBuceo(props) {
  const dispatch = useDispatch();
  const [bussinesDiving, setBussinesDiving] = useState(null);
  const [microsite, setMicrosite] = useState(null);

  let { id } = useParams();
  useEffect(() => {
    dispatch(showLoading(true))
    getBusinessById(id).then((result) => {
      if (result.data.attributes.microsite.data.attributes.menu === null){
        result.data.attributes.microsite.data.attributes.menu = '[]'
      }
      setBussinesDiving(result.data);
      getMicroSiteById(result.data.attributes.microsite.data.id).then(
        (micrositeResult) => {
          setMicrosite(micrositeResult.data.data);
          dispatch(showLoading(false))
        }
        
      );
      
    });
  }, []);

  const setMenu = (value) =>{ 
    bussinesDiving.attributes.microsite.data.attributes.menu = value
  }
  const saveMicrositeDiving = () => {

    let daysMicrosite = {
      Monday:
        bussinesDiving.attributes.booking.data.attributes.Monday,
      Tuesday:
        bussinesDiving.attributes.booking.data.attributes.Tuesday,
      Wednesday:
        bussinesDiving.attributes.booking.data.attributes.Wednesday,
      Thursday:
        bussinesDiving.attributes.booking.data.attributes.Thursday,
      Friday:
        bussinesDiving.attributes.booking.data.attributes.Friday,
      Saturday:
        bussinesDiving.attributes.booking.data.attributes.Saturday,
      Sunday:
        bussinesDiving.attributes.booking.data.attributes.Sunday,
    }

    const dataMicrositeBuceo = {
      commercialName:
        bussinesDiving.attributes.microsite.data.attributes.commercialName,
      description:
        bussinesDiving.attributes.microsite.data.attributes.description,
      aboutUs:
        bussinesDiving.attributes.microsite.data.attributes.aboutUs,
      email1:
        bussinesDiving.attributes.microsite.data.attributes.email1,
      email2:
        bussinesDiving.attributes.microsite.data.attributes.email2,
      latitude:
        bussinesDiving.attributes.microsite.data.attributes.latitude,
      longitude:
        bussinesDiving.attributes.microsite.data.attributes.longitude,
      googlemaps:
        bussinesDiving.attributes.microsite.data.attributes.googlemaps,
      phone1:
        bussinesDiving.attributes.microsite.data.attributes.phone1,
      phone2:
        bussinesDiving.attributes.microsite.data.attributes.phone2,
      facebookUrl:
        bussinesDiving.attributes.microsite.data.attributes.facebookUrl,
      instagramUrl:
        bussinesDiving.attributes.microsite.data.attributes.instagramUrl,
      whatsappPhone:
        bussinesDiving.attributes.microsite.data.attributes.whatsappPhone,
      menu:
        bussinesDiving.attributes.microsite.data.attributes.menu,
    };

    dispatch(showLoading(true))
    updateMicroSite(
      bussinesDiving.attributes.microsite.data.id,
      dataMicrositeBuceo
    )
      .then((result) => {
        updateBookingHeader(bussinesDiving.attributes.booking.data.id, daysMicrosite)
        dispatch(showLoading(false))
        Swal.fire("Datos del micrositio actualizados correctamente");
      })
      .catch((error) => {
        dispatch(showLoading(false))
        Swal.fire("Los datos no se han guardado correctamente")
      });
  };
  if ((bussinesDiving !== null, microsite !== null)) {
    return (
      <>
        <Container>
          <Row>
            <NavBarApplication
              userState={props.setUserState}
            ></NavBarApplication>

            <Col md={6} className="mt-2 width-100">
              <CarouselBanner
                carousel={microsite.attributes.carousel}
                micrositeId={microsite.id}
              ></CarouselBanner>
            </Col>
            {/* <Banner cover={bussinesDiving.attributes.cover} idBusiness={bussinesDiving.id}></Banner> */}
            {/* ---------------------------------------MICROSITE---------------------------------------- */}
            <Col md={6}>
              <InputDescription
                microsite={bussinesDiving.attributes.microsite}
              ></InputDescription>
              <RedesSociales
                microsite={bussinesDiving.attributes.microsite}
              ></RedesSociales>
            </Col>
            <Col md={6}>
              <Maps microsite={bussinesDiving.attributes.microsite}></Maps>
            </Col>
            <Col md={6} mt={4}>
              <BusinessServicesList
                business_services={bussinesDiving.attributes.services_bases.data}
                id={bussinesDiving.id}
                typeServices="bussines"
              ></BusinessServicesList>
            </Col>

            <MenuBasic setMenuValue={setMenu} menuValue={bussinesDiving.attributes.microsite.data.attributes.menu}></MenuBasic>
            <InputDays
              id_Days={bussinesDiving.attributes.booking.data.id}
              days={bussinesDiving.attributes.booking.data.attributes}
            ></InputDays>

            <Button
              className="mt-2 mb-2 w-auto mx-auto"
              onClick={saveMicrositeDiving}
            >
              Guardar datos
            </Button>

            <br />
            {/* -------------------------------------------------------------------------------------------- */}
            <BuceoServices
              booking_Id={bussinesDiving.attributes.booking.data.id}
            ></BuceoServices>
            <Footer></Footer>
          </Row>
        </Container>
      </>
    );
  } else {
    return;
  }
}

export default ConfiguracionesReservaBuceo;
