import { createSlice } from "@reduxjs/toolkit";

const userDefault = {
  jwt: null,
  user: {
    id: 0,
    username: "",
    email: "",
    provider: "",
    confirmed: false,
    blocked: false,
    createdAt: null,
    updatedAt: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: userDefault,
  },
  reducers: {
    setUser: (state, action) => action.payload,
    resetUser: () => userDefault,
  },
});

export const { setUser, resetUser } = userSlice.actions;
export const selectUser = (state) => state.user.value;
export default userSlice.reducer;
