import React, { useEffect, useState } from 'react';
import DetalleReservaActiva from './DetalleReservaActiva'
import './ReservasActivas.css';
import AceptarPago from './AceptarPago';
import DiarioReservas from './DiarioReservas';
import PagosRealizados from './PagosRealizados';
import { getReservationsByBusinessIdAndDateRange } from 'msbookingapiclient';
import { Button } from "react-bootstrap"
import { Col } from 'react-bootstrap';
import Loading from '../../ui/Loading/Loading';

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function ReservasActivas(props) {
    const dispatch = useDispatch();
    const [detail, setDetail] = useState();
    const [aceptPayment, setAceptPayment] = useState();
    const [diaryReservations, setDiaryReservations] = useState();
    const [bussinesReservation, setBussinesReservation] = useState([]);
    const [realicedPayment, setRealicedPayment] = useState();
    const [intervalTime, setIntervalTime] = useState({
        start: "2022-01-01",
        end: "2022-12-14",
    });

    const reservasActivas = () => {
        dispatch(showLoading(true))
        getReservationsByBusinessIdAndDateRange("189", intervalTime.start, intervalTime.end).then((result) => {
            setBussinesReservation(result.data.data);
            dispatch(showLoading(false))
        })
    }


    function inputChangeListener(ev) {
        switch (ev.target.name) {
            case "De":
                intervalTime.start = ev.target.value
                break;
            case "Hasta":
                intervalTime.end = ev.target.value
                break;

            default:
                break;
        }
    }
    function showDetail() {
        setDetail(true);
    }

    const closeDetail = () => {
        setDetail(false)
    }

    const showAceptPayment = () => {
        setAceptPayment(true);
    }

    const closeAceptPayment = () => {
        setAceptPayment(false);
    }

    const showDiaryReservations = () => {
        setDiaryReservations(true);
    }
    const closeDiaryReservations = () => {
        setDiaryReservations(false);
    }

    const showRealicedPayment = () => {
        setRealicedPayment(true);
    }
    const closeRealicedPayment = () => {
        setRealicedPayment(false);
    }

    let active = bussinesReservation.map((item, index) => {
        return (
            <div className="container" key={index}>
                <div className="row">
                    {/* <!--Diseño de tarjeta base--> */}
                    <div className="col-12 mb-3 mt-4">
                        <div className="reservationCard">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <h3 style={{ display: "inline", paddingRight: "10px" }}>Reserva:</h3>
                                        <h3 style={{ display: "inline" }}>{item.id}</h3>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col button-command-pay">
                                        <a href="#" onClick={showAceptPayment} className="white"><i className="fa fa-money fa-lg padding-icon"></i>Aceptar pago</a>
                                    </div>
                                    <div className="col button-command-detail">
                                        <a href="#" onClick={showDetail} className="white"><i className="fa fa-info-circle fa-lg padding-icon"></i>Detalle</a>
                                    </div>
                                    <div className="col button-command-activity">
                                        <a href="#" onClick={showDiaryReservations} className="white"><i className="fa fa-retweet fa-lg padding-icon"></i>Actividad</a>
                                    </div>
                                    <div className="col button-command-payments">
                                        <a href="#" onClick={showRealicedPayment} className="white"><i className="fa fa-credit-card fa-lg padding-icon"></i> Pagos</a>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <p className="label-data">Fecha: </p>
                                        <p className="value-data">{item.attributes.reservationDateTime}</p>
                                    </div>
                                    <div className="col">
                                        <p className="label-data">Valor: </p>
                                        <p className="value-data">{item.attributes.price} $</p>
                                    </div>


                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <p className="label-data">Cliente: </p>
                                        <p className="value-data">Jorge Luis Perez</p>
                                    </div>
                                    <div className="col">
                                        <p className="label-data">Email: </p>
                                        <p className="value-data">{item.attributes.email}</p>
                                    </div>
                                    <div className="col">
                                        <p className="label-data">Teléfono: </p>
                                        <p className="value-data">31032125484</p>
                                    </div>
                                    <hr />
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className="label-data">Estado: </p>
                                        <p className="value-data green">{item.attributes.status}</p>
                                    </div>
                                    <div className="col">
                                        <p className="label-data">Tipo: </p>
                                        <p className="value-data red">{item.attributes.reservationType}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--fin del diseño de tarjeta base--> */}
                </div>
            </div>
        )
    })
    if (bussinesReservation !== null) {
        return (
            <>
                <div className='backgroundpage'>
                    <form className='rangeHours'>
                        <Col className='col-8'>
                            <div className="formActivateReserve">
                                <input id="name" type="date" className="standard-input" name="De" onChange={inputChangeListener} placeholder="" />
                                <label className="floating-label">De:</label>
                            </div>
                            <div className="formActivateReserve">
                                <input id="name" type="date" className="standard-input" name="Hasta" onChange={inputChangeListener} placeholder="" />
                                <label className="floating-label">Hasta:</label>
                            </div>
                        </Col>
                        <Col>
                            <Button onClick={reservasActivas} className="filtro w-auto ml-5 mt-4 mb-2"> Buscar </Button>
                        </Col>
                    </form>
                    {active}
                </div>
                { }
                <DetalleReservaActiva show={detail} onShow={setDetail}></DetalleReservaActiva>
                <AceptarPago show={aceptPayment} onShow={setAceptPayment}></AceptarPago>
                <DiarioReservas show={diaryReservations} onShow={setDiaryReservations}></DiarioReservas>
                <PagosRealizados show={realicedPayment} onShow={setRealicedPayment}></PagosRealizados>
            </>
        );

    } else {
        <Loading></Loading>
    }
}

export default ReservasActivas