import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Servicios.css";
import BusinesService from "./BusinesService";
import {
  getServices,
  setServicesToBusiness,
  setServicesToBookingHotels,
  setServicesToBookingRestaurants,
  setServicesToBookingDivings,
  setServicesToBookingTours,
} from "msbookingapiclient";
import ServicesBooking from "./ServicesBooking";

function BusinessServicesList({ business_services, typeServices, id }) {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [servicesBase, setServiceBase] = useState([]); //todos los servicios existentes

  useEffect(() => {
    getServices()
      .then((result) => {
        result.data.map((item) => {
          if (
            business_services.find((value) => {
              return value.name === item.attributes.name;
            })
          ) {
            item.selected = true;
          } else {
            item.selected = false;
          }
          return item;
        });
        setServiceBase(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateListView = () => {
    servicesBase.map((item) => {
      if (item.selected) {
        const findItem = business_services.find((x) => x.id === item.id);
        if (findItem === undefined) {
          business_services.push(item);
        }
      } else {
        const findItem = business_services.findIndex((x) => x.id === item.id);
        if (findItem !== -1) {
          business_services.splice(findItem);
        }
      }
    });
  };

  const updateServices = () => {
    let SelectedServices = servicesBase.filter((x) => x.selected === true);
    let servicesIdArray = SelectedServices.map((x) => x.id);

    switch (typeServices) {
      case "bussines":
        setServicesToBusiness(id, servicesIdArray)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });

        break;
      case "hotel":
        setServicesToBookingHotels(id, servicesIdArray)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      case "Restaurante":
        setServicesToBookingRestaurants(id, servicesIdArray)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      case "diving":
        setServicesToBookingDivings(id, servicesIdArray)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      case "Toures":
        setServicesToBookingTours(id, servicesIdArray)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      default:
        break;
    }
    updateListView();
    setShow(false);
  };

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      <div className="contenedorServices">
        <h2 className="Services">Servicios</h2>
        <div className="all-services ">
          {business_services.map((item) => (
            <BusinesService
              service={item}
              name={item.name}
              key={item.id}
            ></BusinesService>
          ))}
        </div>

        <Button
          size="sm"
          className=" btn btn-primary w-auto "
          onClick={() => handleShow()}
        >
          Modificar servicios
        </Button>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Servicios</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light services">
          Seleccione los servicios que presta: <br />
          <div className="checkboxes">
            <ServicesBooking servicesBase={servicesBase}></ServicesBooking>
          </div>
        </Modal.Body>
        <Button
          style={{ width: "30%", marginLeft: "30%" }}
          className="saveServices"
          onClick={updateServices}
        >
          Guardar
        </Button>
      </Modal>
    </>
  );
}
export default BusinessServicesList;
