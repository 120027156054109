import React from "react";
import BusinesServiceSelect from "./BusinesServiceSelect";

function ServicesBookingHotel(props) {
  return (
    <div>
      {props.servicesBase.map((item) => (
        <BusinesServiceSelect
          service={item}
          businessService={props.servicesBase}
          key={item.id}
        ></BusinesServiceSelect>
      ))}
    </div>
  );
}

export default ServicesBookingHotel;
