import React from "react";
import "./InputDescription.css";

function InputDescription(props) {

  const inputChangeListener = (ev)=> {
    switch (ev.target.name) {
      case "commercialName":
          props.microsite.data.attributes.commercialName = ev.target.value;
        break;
        case "phone1":
          props.microsite.data.attributes.phone1 = ev.target.value;
        break;
        case "phone2":
          props.microsite.data.attributes.phone2 = ev.target.value;
        break;
        case "email1":
          props.microsite.data.attributes.email1 = ev.target.value;
        break;
        case "email2":
          props.microsite.data.attributes.email2 = ev.target.value;
        break;
        case "description":
          props.microsite.data.attributes.description = ev.target.value;
        break;
        case "aboutUs":
          props.microsite.data.attributes.aboutUs = ev.target.value;
        break;
    
      default:
        break;
    }
  }

  return (
    <>
      <div className="DescriptionService">

        <h3>Información del negocio</h3>
        <br></br>
        <div className="form-block">          
          <input defaultValue={props.microsite.data.attributes.commercialName} name="commercialName" id="name" type="text" className="standard-input" placeholder="Nombre del Negocio" onChange={inputChangeListener} />          
        </div>

        <div style={{ display: "flex" }}>
          <div className="Phone1">
            <input defaultValue={props.microsite.data.attributes.phone1} name="phone1" id="name" type="text" className="standard-input" placeholder="Telefono 1" onChange={inputChangeListener} />
          </div>
    
          <div className="Phone2">
            <input defaultValue={props.microsite.data.attributes.phone2} name="phone2" id="name" type="text" className="standard-input" placeholder="Telefono 2" onChange={inputChangeListener} />
          </div>

        </div>
        <div style={{ display: "flex" }}>
        <div className="Email1">
            <input defaultValue={props.microsite.data.attributes.email1} name="email1" id="name" type="text" className="standard-input" placeholder="Correo 1 @" onChange={inputChangeListener} />
          </div>
          <br/>
          <div className="Email2">
            <input defaultValue={props.microsite.data.attributes.email2} name="email2" id="name" type="text" className="standard-input" placeholder="Correo 2 @" onChange={inputChangeListener} />
          </div>
        </div>
        

        <h2 id="Title"></h2>
        <div className="textArea">
          <textarea defaultValue={props.microsite.data.attributes.description} name="description" id="name" type="text" className="standard-input" placeholder="Descripción" onChange={inputChangeListener} />
        </div>
        <div className="textArea">
          <textarea defaultValue={props.microsite.data.attributes.aboutUs} name="aboutUs" id="name" type="text" className="standard-input" placeholder="Acerca de nosotros" onChange={inputChangeListener} />
        </div>
      </div>
    </>
  );
}

export default InputDescription;
