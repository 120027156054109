import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./BuceoServices.css";
import CardBuseo from "./CardBuseo";
import AddNewBuceo from "../NewBuceo/AddNewBuceo";
import EditBuseo from "./EditBuseo";
import { getBookingById, deleteBookingDiving, cloneBookingDiving } from 'msbookingapiclient';
import {Card, Row } from 'react-bootstrap'
import Swal from "sweetalert2";
//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function BuceoServices(props) {
  const dispatch = useDispatch();
  const[itemData, setItemData] = useState({})
  const [showBuceo, setShowBuceo] = useState();
  const [showItemEdit, setShowItemEdit] = useState(false);
  const [bookingItem, setBookingItem] = useState({
    id: 0,
    booking_divings: [],
  });

  const showItemEditState = (stateBoolean, index)=>{
    setItemData(bookingItem.booking_divings[index])
    setShowItemEdit(stateBoolean)
  }
  const closeItemEditListener = (onShow) => {    
    setShowItemEdit(onShow);
  };

  const showNewBuceo = () => {
    setShowBuceo(true);
  };
  
  const Clone =(id)=>{
    Swal.fire({
      title: '¿Seguro de clonar este servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Clonar'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        cloneBookingDiving(props.booking_Id, id).then((result)=>{
          let newSlot = result;
          newSlot.attributes.id = result.id;
          bookingItem.booking_hotels.push(newSlot.attributes);
          dispatch(showLoading(false));
        }).catch((error)=>{
          dispatch(showLoading(false));
          Swal.fire("El servicio ha sido clonado correctamente")
        })
      }
      return
    })
  }

  const Delete = (id)=>{
    Swal.fire({
      title: '¿Seguro de borrar este servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar'
    }).then((result) => {
      if (result.isConfirmed) { 
        dispatch(showLoading(true))
        deleteBookingDiving(id).then((result)=>{
          const index = bookingItem.booking_divings.findIndex(item => item.id === result.data.data.id);
          bookingItem.booking_divings.splice(index, 1);
          dispatch(showLoading(false));
          Swal.fire("El servicio se ha eliminado correctamente")
        }).catch((error)=>{
          dispatch(showLoading(false));
          Swal.fire("El servicio no se ha eliminado correctamente")
        })
      }
      return
    })
  }

  useEffect(() => {
    dispatch(showLoading(true))
    getBookingById(props.booking_Id)
      .then((result) => {
        const filterList = result.data.booking_divings.filter((item)=> item.status === true)
        result.data.booking_divings = filterList
        setBookingItem(result.data);
        dispatch(showLoading(false))
      })
      .catch((error) => {
        dispatch(showLoading(false))
        Swal.fire("Ha habido un error al cargar sus servicios, por favor comuniquese con el administrador")
      });
  }, [props.booking_Id]);

  let Buseo = bookingItem.booking_divings.map((item, index) => {
    return (
      <div key={index}>
        <Card key="" style={{ width: "100%" }} className="mb-2 cardBuceo">
            <Card.Header>
              <div className="ToolBox">
                <Button onClick={()=>showItemEditState(true, index)} className="bg-primary" size="sm">
                  <i className="bi bi-pencil-square"></i>Editar
                </Button>
                <Button className="bg-primary"  onClick={() => {Clone(item.id)}} size="sm">
                  <i className="bi bi-clipboard-check-fill"></i>Clonar
                </Button>
                <Button onClick={()=>{Delete(item.id)} } className="bg-primary" size="sm">
                  <i className="bi bi-trash3"></i>Borrar
                </Button>
              </div>
            </Card.Header>
            <div className="FormCard">
              <Card.Body>
                <CardBuseo Buseo={item}></CardBuseo>
                <Card.Footer>
                </Card.Footer>
              </Card.Body>
            </div>
          </Card>
        
      </div>
    );
  });
  return (
    <>
      <div>
        <h3>Servicios de Buseo</h3>

        <Row md={3}>{Buseo}</Row>
        <AddNewBuceo show={showBuceo} onShow={setShowBuceo} booking_Id={props.booking_Id} bookingList={bookingItem.booking_divings}></AddNewBuceo>
        <EditBuseo show={showItemEdit} onShow={setShowItemEdit} booking_Id={props.booking_Id} Buseo={itemData}></EditBuseo>
        <Button onClick={showNewBuceo} className='mx-auto mb-2 w-auto'>Nuevo buceo</Button>
      </div>
    </>
  );
}

export default BuceoServices;
