import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import AddImageServices from "../../ui/AddImageServices/AddImageServices";
import Swal from "sweetalert2";
import { editBookingDiving } from "msbookingapiclient";
//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function EditCardBuseo(props) {
    const dispatch = useDispatch();
    const [countChildren, setCountChildren] = useState();
    const [item, setItem] = useState({
        name: "",
        capacity: 0,
        price: 0,
        description: "",
        status: true,
    });
    useEffect(() => {
        setItem(props.Buseo);
        setCountChildren(props.Buseo.capacity);
    })

    const countChildrenItem = (quantity) => {
        setCountChildren(quantity);
        item.capacity = quantity;
    };

    const onChangeTextListener = (event) => {
        switch (event.target.name) {
            case "name":
                item.name = event.target.value;
                break;
            case "description":
                item.description = event.target.value;
                break;
            case "capacity":
                item.capacity = event.target.value;
                break;
            case "price":
                item.price = event.target.value;
                break;

            default:
                break;
        }
    };
    const setShow = (isShow) => {
        props.onShow(isShow);
    };

    const editRoomHotel = () => {
        Swal.fire({
            title: "Esta seguro de guardar cambios?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(showLoading(true))
                editBookingDiving(item.id, item).then((result) => {
                    dispatch(showLoading(false))
                    Swal.fire({
                        title: "Servicio de tour editado correctamente",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            setShow(false);
                        }
                    });
                });
            } else if (result.isDenied) {
                return;
            }
        });
    };

    if (props === null) {
        return (<><h1>...loading</h1></>)
    }
    return (
        <>
            <Modal show={props.show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Servicio de Habitacion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="FormDiv">
                        <Form.Group
                            className="mb-3 mb"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <div className="divFormEdit">
                                <input defaultValue={props.Buseo.description} onChange={onChangeTextListener} name="description" id="name" type="text" className="standard-input mt-3" placeholder="Descripción" />
                            </div>
                        </Form.Group>

                        <label>Capacidad:</label>

                        <Button
                            style={{ marginLeft: "22px" }}
                            onClick={() => countChildrenItem(countChildren + 1)}
                        >
                            +
                        </Button>
                        <label
                            style={{ marginLeft: "10px" }}
                            name="capacity"
                        >

                            {" "}{countChildren}{" "}
                        </label>

                        <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => countChildrenItem(countChildren - 1)}
                        >
                            -
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary">Close</Button>
                    <Button variant="primary" onClick={editRoomHotel}>
                        Guardar cambios
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditCardBuseo