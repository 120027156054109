import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

//multilang
import "./i18n/i18n";

//redux persist
import { Provider } from "react-redux";
import store from "./store/index";
import Navigation from "./components/Navigation";
import { BrowserRouter as Router } from "react-router-dom";
import { ApiEndPoint } from "msbookingapiclient";
import Loading from "./ui/Loading/Loading";

function App() {
  sessionStorage.setItem("_APIDOMAIN", "https://api.macrodatum.com/api");
  ApiEndPoint.domain = sessionStorage.getItem("_APIDOMAIN");

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Loading></Loading>
          <Navigation></Navigation>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
