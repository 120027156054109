import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './TouresServices.css'
import CardToures from './CardToures';
import AddNewTour from '../NewTour/AddNewTour';
import { getBookingById, deleteBookingTour, cloneBookingTour } from 'msbookingapiclient';
import EditCardTours from './EditCardTours';
import Swal from "sweetalert2";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<


function TouresServices(props) {
  const dispatch = useDispatch();
  const [itemData, setItemData] = useState({})
  const [showTour, setShowTour] = useState(false);
  const [showItemEdit, setShowItemEdit] = useState(false);
  const [bookingItem, setBookingItem] = useState({
    id: 0,
    booking_tours: [],
  });

  useEffect(() => {
    getBookingById(props.booking_Id)
      .then((result) => {
        const filterList = result.data.booking_tours.filter((item) => item.status === true)
        result.data.booking_tours = filterList
        setBookingItem(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.booking_Id]);

  const showItemEditState = (stateBoolean, index) => {
    setItemData(bookingItem.booking_tours[index])
    setShowItemEdit(stateBoolean)
  }
  const closeItemEditListener = (onShow) => {
    setShowItemEdit(onShow);
  };

  const Clone = (id) => {
    Swal.fire({
      title: '¿Seguro de clonar este servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Clonar'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        cloneBookingTour(props.booking_Id, id).then((result) => {
          let newSlot = result;
          newSlot.attributes.id = result.id;
          bookingItem.booking_tours.push(newSlot.attributes);
          dispatch(showLoading(false))
          Swal.fire("El servicio ha sido clonado correctamente")
        }).catch((error)=>{
          dispatch(showLoading(false))
          Swal.fire("El servicio no ha sido clonado correctamente")
        })
      }
      return
    })
  }

  const Delete = (id) => {
    Swal.fire({
      title: '¿Seguro de borrar este servicio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        deleteBookingTour(id).then((result) => {
          const index = bookingItem.booking_tours.findIndex(item => item.id === result.data.data.id);
          bookingItem.booking_tours.splice(index, 1);
          dispatch(showLoading(false));
          Swal.fire("El servicio ha sido eliminado correctamente")
        }).catch((error)=>{
          dispatch(showLoading(false))
          Swal.fire("EL servicio no ha sido eliminado correctamente")
        })
      }
      return
    })
  }

  const showNewTour = () => {
    setShowTour(true);
  };

  const closeNewTour = () => {
    setShowTour(false);
  };

  let tours = bookingItem.booking_tours.map((item, index) => {
    return (
      <div key={index}>
        <Card  key="" style={{ width: "95%" }} className="mb-2 cardTouress">
          <Card.Header>
            <div className="ToolBox">
              <Button onClick={() => showItemEditState(true, index)} className="bg-primary" size="sm">
                <i className="bi bi-pencil-square"></i>Editar
              </Button>
              <Button className="bg-primary" onClick={() => { Clone(item.id) }} size="sm">
                <i className="bi bi-clipboard-check-fill"></i>Clonar
              </Button>
              <Button onClick={() => { Delete(item.id) }} className="bg-primary" size="sm">
                <i className="bi bi-trash3"></i>Borrar
              </Button>
            </div>
          </Card.Header>
          <div className="FormCard">
            <Card.Body>
              <CardToures tours={item}></CardToures>
            </Card.Body>
          </div>
        </Card>

      </div>
    );
  });
  return (
    <>
      <Row>
        <h2>Configuracion de Transportes</h2>
        <Col>
          {
            tours
          }

          <AddNewTour show={showTour} onShow={setShowTour} booking_Id={props.booking_Id} bookingList={bookingItem.booking_tours}></AddNewTour>
          <EditCardTours tours={itemData} show={showItemEdit} onShow={closeItemEditListener} ></EditCardTours>
          <Button onClick={showNewTour} className='mx-auto mb-2 w-auto'>Añadir transorte</Button>
        </Col>
      </Row>

    </>
  )
}

export default TouresServices