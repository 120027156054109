import React, { useState, useEffect, useInsertionEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./RoomServices.css";
import AddImageServices from "../../ui/AddImageServices/AddImageServices";
import Swal from "sweetalert2";
import { editBookingHotel } from "msbookingapiclient";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function EditRoom(props) {
  const dispatch = useDispatch();
  const [countAdults, setCountAdults] = useState(0);
  const [countChildren, setCountChildren] = useState(0);
  const [files, setFiles] = useState([])
  const [item, setItem] = useState({
    name: "",
    adultsCapacity: 0,
    childrenCapacity: 0,
    priceAdult: 0,
    priceChildren: 0,
    description: "",
    status: true,
    preferenceReservation: "Turismo",
    checkin: "00:00:00.000",
    checkout: "00:00:00.000",
  });

  useEffect(() => {
    setItem(props.room);
    setCountAdults(props.room.adultsCapacity);
    setCountChildren(props.room.childrenCapacity);
  })

  const countAdultsItem = (quantity) => {
    setCountAdults(quantity);
    item.adultsCapacity = quantity;
  };

  const countChildrenItem = (quantity) => {
    setCountChildren(quantity);
    item.childrenCapacity = quantity;
  };

  const onChangeTextListener = (event) => {
    switch (event.target.name) {
      case "name":
        item.name = event.target.value;
        break;
      case "description":
        item.description = event.target.value;
        break;
      case "priceAdult":
        item.priceAdult = event.target.value;
        break;
      case "priceChildren":
        item.priceChildren = event.target.value;
        break;
      case "checkin":
        item.checkin = event.target.value;
        break;
      case "checkout":
        item.checkout = event.target.value;
        break;

      default:
        break;
    }
  };

  const setShow = (isShow) => {
    props.onShow(isShow);
  };

  const editRoomHotel = () => {
    Swal.fire({
      title: "Esta seguro de guardar cambios?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        editBookingHotel(item.id, item, files).then((result) => {
          dispatch(showLoading(false));
          Swal.fire({
            title: "Cuarto editado correctamente",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setShow(false);
            }
          });
        });
      } else if (result.isDenied) {
        return;
      }
    });

    //enviar datos al servicio
  };

  if (props === null) {
    return (<><h1>...loading</h1></>)
  }
  return (
    <>
      <Modal show={props.show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Servicio de Habitacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="FormDiv">
            <Form.Group
              className="mb-3  mb"
              controlId="exampleForm.ControlInput1"
            >
              <div className="divFormEdit">
                <input onChange={onChangeTextListener} defaultValue={props.room.name} name="name" type="text" className="standard-input" placeholder="Nombre" />
              </div>
            </Form.Group>
            <Form.Group
              className="mb-3 mb"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="divFormEdit">
                <input defaultValue={props.room.description} id="name" type="text" name="description" className="standard-input" placeholder="Descripción" />
              </div>
            </Form.Group>
            <div>
              <label>Número de Adultos:</label>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => countAdultsItem(countAdults + 1)}
              >
                +
              </Button>
              <label name="adultsCapacity" style={{ marginLeft: "10px" }}> {countAdults} </label>

              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => countAdultsItem(countAdults - 1)}
              >
                -
              </Button>
              <br></br>
              <br></br>
              <label>Número de Niños:</label>

              <Button
                style={{ marginLeft: "22px" }}
                onClick={() => countChildrenItem(countChildren + 1)}
              >
                +
              </Button>
              <label
                value={props.adultsCapacity}
                style={{ marginLeft: "10px" }}
              >
                {" "}
                {countChildren}{" "}
              </label>

              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => countChildrenItem(countChildren - 1)}
              >
                -
              </Button>
              <br />
              <br />
            </div>
            <div className="divFormEdit">
              <input defaultValue={props.room.priceAdult} id="name" name="priceAdult" type="text" className="standard-input" placeholder="Precio por adulto" />
            </div>
            <br />
            <div className="divFormEdit">
              <input defaultValue={props.room.priceChildren} id="name" name="priceChildren" type="text" className="standard-input" placeholder="Precio por niño" />
            </div>
            <br />
            <div className="divFormEdit">
              <input defaultValue={props.room.checkin} id="name" name="checkin" type="Time" className="standard-input" placeholder=" " />
              <label className="floating-label">CheckIn</label>
            </div>
            <br />
            <div className="divFormEdit">
              <label className="floating-label">CheckOut</label>
              <input defaultValue={props.room.checkout} id="name" name="checkout" type="Time" className="standard-input" placeholder=" " />
              
            </div>

            <div className="Image_NewRoom">
              <AddImageServices
                carousel={props.room.carousel}
                files={files}
                id={props.room.id}
                typeAsset={"ImagesToBookingHotels"}
              ></AddImageServices>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={editRoomHotel}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EditRoom;
