import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import TouresServices from "../components/TouresServices/TouresServices";
import InputDays from "../ui/InputDaysConfiguration/InputDays";
import InputDescription from "../components/InputDescription/InputDescription";
import Maps from "../ui/Maps/Maps";
import {
  getBusinessById,
  updateMicroSite,
  getMicroSiteById,
} from "msbookingapiclient";
import { updateBookingHeader } from "msbookingapiclient/src/Booking";
import Footer from "../components/Footer";
import RedesSociales from "../components/ConfiguracionRedesSociales/RedesSociales";
import { Button } from "react-bootstrap";
import CarouselBanner from "../ui/CarouselMicrosite/CarouselBanner";
import BusinessServicesList from "../ui/Services/BusinessServicesList";
import Loading from "../ui/Loading/Loading";
//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../store/slices/Loader/loadingReducer";
//redux<

function ConfiguracionesReservaToures(props) {
  const dispatch = useDispatch();
  const [bussinesToures, setBussinesToures] = useState(null);
  const [microsite, setMicrosite] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    dispatch(showLoading(true))
    getBusinessById(id).then((result) => {
      setBussinesToures(result.data);
      getMicroSiteById(result.data.attributes.microsite.data.id).then(
        (micrositeResult) => {
          setMicrosite(micrositeResult.data.data);
          dispatch(showLoading(false))
        }
      );
    });
  }, []);

  const saveMicrositeToures = () => {
    let daysMicrosite = {
      Monday:
        bussinesToures.attributes.booking.data.attributes.Monday,
      Tuesday:
        bussinesToures.attributes.booking.data.attributes.Tuesday,
      Wednesday:
        bussinesToures.attributes.booking.data.attributes.Wednesday,
      Thursday:
        bussinesToures.attributes.booking.data.attributes.Thursday,
      Friday:
        bussinesToures.attributes.booking.data.attributes.Friday,
      Saturday:
        bussinesToures.attributes.booking.data.attributes.Saturday,
      Sunday:
        bussinesToures.attributes.booking.data.attributes.Sunday,
    }

    let dataMicrosite = {
      commercialName:
        bussinesToures.attributes.microsite.data.attributes.commercialName,
      description:
        bussinesToures.attributes.microsite.data.attributes.description,
      aboutUs:
        bussinesToures.attributes.microsite.data.attributes.aboutUs,
      email1:
        bussinesToures.attributes.microsite.data.attributes.email1,
      email2:
        bussinesToures.attributes.microsite.data.attributes.email2,
      latitude:
        bussinesToures.attributes.microsite.data.attributes.latitude,
      longitude:
        bussinesToures.attributes.microsite.data.attributes.longitude,
      googlemaps:
        bussinesToures.attributes.microsite.data.attributes.googlemaps,
      phone1:
        bussinesToures.attributes.microsite.data.attributes.phone1,
      phone2:
        bussinesToures.attributes.microsite.data.attributes.phone2,
      facebookUrl:
        bussinesToures.attributes.microsite.data.attributes.facebookUrl,
      instagramUrl:
        bussinesToures.attributes.microsite.data.attributes.instagramUrl,
      whatsappPhone:
        bussinesToures.attributes.microsite.data.attributes.whatsappPhone,
    };

    dispatch(showLoading(true))
    updateMicroSite(bussinesToures.attributes.microsite.data.id, dataMicrosite)
      .then((result) => {
        updateBookingHeader(bussinesToures.attributes.booking.data.id, daysMicrosite)
          .then((result) => {
            dispatch(showLoading(false))
            Swal.fire("Datos del micrositio actualizados correctamente");
          })
      })
      .catch((error) => {
        dispatch(showLoading(false))
        Swal.fire("Los datos no se han guardado correctamente")
      });
  };

  if ((bussinesToures !== null, microsite !== null)) {
    return (
      <>
        <Container>
          <Row>
            <NavBarApplication
              userState={props.setUserState}
            ></NavBarApplication>
            <Col md={6} className="mt-2 width-100">
              <CarouselBanner
                carousel={microsite.attributes.carousel}
                micrositeId={microsite.id}
              ></CarouselBanner>
            </Col>
            {/* --------------------------------------MICROSITE----------------------------------------- */}
            <Col mg={6}>
              <InputDescription
                microsite={bussinesToures.attributes.microsite}
              ></InputDescription>
              <RedesSociales
                microsite={bussinesToures.attributes.microsite}
              ></RedesSociales>
            </Col>
            <Col md={6}>
              <Maps microsite={bussinesToures.attributes.microsite}></Maps>
            </Col>
            <Col md={6} mt={4}>
              <BusinessServicesList
                business_services={bussinesToures.attributes.services_bases.data}
                id={bussinesToures.id}
                typeServices="bussines"
              ></BusinessServicesList>
            </Col>

            <InputDays
              id_Days={bussinesToures.attributes.booking.data.id}
              days={bussinesToures.attributes.booking.data.attributes}
            ></InputDays>
            
            <Button
              className="mt-2 mb-2 w-auto mx-auto"
              onClick={saveMicrositeToures}
            >
              Guardar datos
            </Button>
            {/* ----------------------------------------------------------------------------------------- */}
            <TouresServices
              booking_Id={bussinesToures.attributes.booking.data.id}
            ></TouresServices>
            <Footer></Footer>
          </Row>
        </Container>
      </>
    );
  } else {
    return
  }
}

export default ConfiguracionesReservaToures;
