/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import PageNotFound from "../pages/PageNotFound";
import Login from "./Login";
import ConfiguracionesReservaHospedaje from "../pages/ConfiguracionesReservaHospedaje";
import ConfiguracionesReservaRestaurante from "../pages/ConfiguracionesReservaRestaurante";
import ConfiguracionesReservaToures from "../pages/ConfiguracionesReservaToures";
import ConfiguracionesReservaBuceo from "../pages/ConfiguracionesReservaBuceo";
import ConfiguracionReservasActivas from "../pages/ConfiguracionReservasActivas";
import TusIngresos from "../pages/TusIngresos";
import TusComunicaciones from "../pages/TusComunicaciones";
import MediosdePago from "../pages/MediosdePago";
import { getCompanyById } from "msbookingapiclient";

//redux>
import { useSelector, useDispatch } from "react-redux";
import { setCompany, resetCompany } from "../store/slices/company";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
//redux<

function Navigation() {
  //redux
  // const userState = useSelector((state) => state.user);

  const [userState, setUserState] = useState(
    JSON.parse(sessionStorage.getItem("_msSession"))
  );
  const companyState = useSelector((state) => state.company);
  const idCompany = JSON.parse(localStorage.getItem("MSCOMPANY"));
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("MSCOMPANY") !== null) {
      getCompanyById(idCompany.id)
        .then((result) => {
          sessionStorage.setItem("MSCOMPANY", JSON.stringify(result.data));
          Navigate.to("/");
          dispatch(setCompany(result.data));
        })
        .catch((error) => {;
        });
    }
  }, [userState]);

  return (
    <>
      <Routes>
        <Route
          exact
          path="/Login"
          element={<Login userState={userState} setUserState={setUserState} />}
        />
        <Route
          exact
          path="/"
          element={
            <PrivateRoute userState={userState}>
              <Home />
            </PrivateRoute>
          }
        ></Route>
        <Route
          exact
          path="/ConfiguracionesReservaHospedaje/:id"
          element={
            <PrivateRoute userState={userState}>
              <ConfiguracionesReservaHospedaje userState={setUserState} />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/ConfiguracionesReservaRestaurante/:id"
          element={
            <PrivateRoute userState={userState}>
              <ConfiguracionesReservaRestaurante />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/ConfiguracionesReservaToures/:id"
          element={
            <PrivateRoute userState={userState} redirectTo="/Login">
              <ConfiguracionesReservaToures />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/ConfiguracionesReservaBuceo/:id"
          element={
            <PrivateRoute userState={userState}>
              <ConfiguracionesReservaBuceo />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/MediosdePago"
          element={
            <PrivateRoute userState={userState}>
              <MediosdePago />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/ConfiguracionReservasActivas"
          element={
            <PrivateRoute userState={userState}>
              <ConfiguracionReservasActivas />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/TusIngresos"
          element={
            <PrivateRoute userState={userState}>
              <TusIngresos />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/TusComunicaciones"
          element={
            <PrivateRoute userState={userState}>
              <TusComunicaciones />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/About"
          element={
            <PrivateRoute userState={userState}>
              <About />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default Navigation;
