import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AceptarPago(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);


  return (
    <>
      <Modal show={props.show} onHide={() => props.onShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalle de la reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea aceptar pago?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AceptarPago;