import React, { useState } from "react";
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import InputDays from "../ui/InputDaysConfiguration/InputDays";
import Maps from "../ui/Maps/Maps";
import BusinessServicesList from "../ui/Services/BusinessServicesList";
import { useParams } from "react-router-dom";
import {
  getBusinessById,
  updateMicroSite,
  getMicroSiteById,
} from "msbookingapiclient";
import { updateBookingHeader } from "msbookingapiclient/src/Booking";
import { useEffect } from "react";
import InputDescription from "../components/InputDescription/InputDescription";
import TableRestaurantList from "../components/RestaurantServices/TableRestaurantList";
import RedesSociales from "../components/ConfiguracionRedesSociales/RedesSociales";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import CarouselBanner from "../ui/CarouselMicrosite/CarouselBanner";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../store/slices/Loader/loadingReducer";
import MenuBasic from "../components/Menu/MenuBasic"
//redux<

function ConfiguracionesReservaRestaurante(props) {
  const dispatch = useDispatch();

  const [newServicesArray, setNewServicesArray] = useState([]);
  const [bussinesRestaurant, setBussinesRestaurant] = useState(null);
  const [microsite, setMicrosite] = useState(null);

  let { id } = useParams();

  useEffect(() => {

    //cambiar a true
    dispatch(showLoading(true));

    getBusinessById(id).then((result) => {
      if (result.data.attributes.microsite.data.attributes.menu === null) {
        result.data.attributes.microsite.data.attributes.menu = '[]'
      }
      setBussinesRestaurant(result.data);
      getMicroSiteById(result.data.attributes.microsite.data.id).then((micrositeResult) => {
        const arrayServices = result.data.attributes.services_bases.data.map((item) => {
          return {
            name: item.attributes.name,
            id: item.id,
          }
        })
        setNewServicesArray(arrayServices);
        setMicrosite(micrositeResult.data.data);
        //cambiar a false
        dispatch(showLoading(false));
      }
      );
    }).catch((error) => {
      //cambiar la variable a false
      dispatch(showLoading(false));
    });
  }, []);


  const setMenu = (value) => {
    bussinesRestaurant.attributes.microsite.data.attributes.menu = value
  }

  const saveMicrositeRestaurant = () => {
    let daysMicrosite = {
      Monday:
        bussinesRestaurant.attributes.booking.data.attributes.Monday,
      Tuesday:
        bussinesRestaurant.attributes.booking.data.attributes.Tuesday,
      Wednesday:
        bussinesRestaurant.attributes.booking.data.attributes.Wednesday,
      Thursday:
        bussinesRestaurant.attributes.booking.data.attributes.Thursday,
      Friday:
        bussinesRestaurant.attributes.booking.data.attributes.Friday,
      Saturday:
        bussinesRestaurant.attributes.booking.data.attributes.Saturday,
      Sunday:
        bussinesRestaurant.attributes.booking.data.attributes.Sunday,
    }

    let dataMicrosite = {
      commercialName:
        bussinesRestaurant.attributes.microsite.data.attributes.commercialName,
      description:
        bussinesRestaurant.attributes.microsite.data.attributes.description,
      aboutUs:
        bussinesRestaurant.attributes.microsite.data.attributes.aboutUs,
      email1:
        bussinesRestaurant.attributes.microsite.data.attributes.email1,
      email2:
        bussinesRestaurant.attributes.microsite.data.attributes.email2,
      latitude:
        bussinesRestaurant.attributes.microsite.data.attributes.latitude,
      longitude:
        bussinesRestaurant.attributes.microsite.data.attributes.longitude,
      googlemaps:
        bussinesRestaurant.attributes.microsite.data.attributes.googlemaps,
      phone1:
        bussinesRestaurant.attributes.microsite.data.attributes.phone1,
      phone2:
        bussinesRestaurant.attributes.microsite.data.attributes.phone2,
      facebookUrl:
        bussinesRestaurant.attributes.microsite.data.attributes.facebookUrl,
      instagramUrl:
        bussinesRestaurant.attributes.microsite.data.attributes.instagramUrl,
      whatsappPhone:
        bussinesRestaurant.attributes.microsite.data.attributes.whatsappPhone,
      twitter:
        bussinesRestaurant.attributes.microsite.data.attributes.twitter,
      menu:
        bussinesRestaurant.attributes.microsite.data.attributes.menu,
    };
    dispatch(showLoading(true))
    updateMicroSite(
      bussinesRestaurant.attributes.microsite.data.id,
      dataMicrosite
    )
      .then((result) => {
        updateBookingHeader(bussinesRestaurant.attributes.booking.data.id, daysMicrosite)
          .then((result) =>{
            dispatch(showLoading(false))
            Swal.fire("Datos del micrositio actualizados correctamente");
          })
      })
      .catch((error) => {
        dispatch(showLoading(false))
        Swal.fire("Los datoos no se han guardado correctamente")
      });
  };
  if (bussinesRestaurant !== null && microsite !== null) {
    return (
      <>
        <Container>
          <Row>
            <NavBarApplication userState={props.setUserState} />
            <Col md={6} className="mt-2 width-100">
              <CarouselBanner
                carousel={microsite.attributes.carousel}
                micrositeId={microsite.id}
              ></CarouselBanner>
            </Col>
            {/* <Banner cover={bussinesRestaurant.attributes.cover} idBusiness={bussinesRestaurant.id}></Banner> */}
            {/* -----------------------------------MICROSITE-------------------------------------------- */}
            <Col md={6}>
              <InputDescription
                microsite={bussinesRestaurant.attributes.microsite}
              ></InputDescription>
              <RedesSociales
                microsite={bussinesRestaurant.attributes.microsite}
              ></RedesSociales>
            </Col>
            <Col md={6}>
              <Maps microsite={bussinesRestaurant.attributes.microsite}></Maps>
            </Col>
            <Col md={6} mt={4}>
              <BusinessServicesList
                business_services={newServicesArray}
                id={bussinesRestaurant.id}
                typeServices="bussines"
              ></BusinessServicesList>
            </Col>

            <MenuBasic setMenuValue={setMenu} menuValue={bussinesRestaurant.attributes.microsite.data.attributes.menu}></MenuBasic>

            <InputDays
              id_Days={bussinesRestaurant.attributes.booking.data.id}
              days={bussinesRestaurant.attributes.booking.data.attributes}
            ></InputDays>

            <Button
              className="mt-2 mb-2 w-auto mx-auto"
              onClick={saveMicrositeRestaurant}
            >
              Guardar datos
            </Button>
            {/* --------------------------------------------------------------------------------------- */}
            <TableRestaurantList
              booking_Id={bussinesRestaurant.attributes.booking.data.id}
            ></TableRestaurantList>
            <Footer></Footer>
          </Row>
        </Container>
      </>
    );
  } else {
    return;
  }
}
export default ConfiguracionesReservaRestaurante;
