import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./ServicesRestaurant.css";
import AddImageServices from "../../ui/AddImageServices/AddImageServices";
import Swal from "sweetalert2";
import { editBookingRestaurant } from "msbookingapiclient";
import Loading from "../../ui/Loading/Loading";
import BusinessServicesList from "../../ui/Services/BusinessServicesList";
//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function EditCardRestaurant(props) {
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [item, setItem] = useState({
    capacity: 0,
    status: true,
  });
  useEffect(() => {
    setItem(props.table);
    setCount(props.table.capacity);
  },)

  const setCountPerson = (quantity) => {
    setCount(quantity);
    item.capacity = quantity;
  };

  const onChangeTextListener = (event) => {
    switch (event.target.name) {
      case "capacity":
        item.capacity = event.target.value;
        break;
      default:
        break;
    }
  };

  const setShow = (isShow) => {
    props.onShow(isShow);
  };

  const editRoomHotel = () => {
    Swal.fire({
      title: "Esta seguro de guardar cambios?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        editBookingRestaurant(item.id, item).then((result) => {
          dispatch(showLoading(false))
          Swal.fire({
            title: "Mesa editada correctamente",
            confirmButtonText: "Ok",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              setShow(false);
            }
          });
        }).catch((error) => {
          debugger
          Swal.fire("La mesa no ha sido editada")
        });
      } else if (result.isDenied) {
        return;
      }
    });
  };

  if (props === null) {
    return (<><Loading></Loading></>)
  }
  return (
    <>
      <Modal show={props.show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Servicio de Habitacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="FormDiv">
            <div>
              <label>Capacidad:</label>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => setCountPerson(count + 1)}
              >
                +
              </Button>
              {" "}
              <label
                name="capacity"
                onChange={onChangeTextListener}
                style={{ marginLeft: "10px" }}
              > {count}
              </label>
              {" "}
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => setCountPerson(count - 1)}
              >
                -
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger">Cancelar</Button>
          <Button variant="primary" onClick={editRoomHotel}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EditCardRestaurant;
