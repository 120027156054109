import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddImageServices from "../../ui/AddImageServices/AddImageServices";
import Swal from "sweetalert2";
import { addBookingHotel } from "msbookingapiclient";
import "./AddNewRoom.css";
import BusinessServicesList from "../../ui/Services/BusinessServicesList";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function AddNewRoom(props) {
  const dispatch = useDispatch();
  const [countAdults, setCountAdults] = useState(0);
  const [countChildren, setCountChildren] = useState(0);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newHotelBooking, setNewHotelBooking] = useState({
    name: "",
    adultsCapacity: 0,
    childrenCapacity: 0,
    priceAdult: 0,
    priceChildren: 0,
    description: "",
    status: true,
    preferenceReservation: "Turismo",
    checkin: "00:00:00.000",
    checkout: "00:00:00.000",
    services_bases: [],
  });

  const saveBooking = async () => {
    Swal.fire({
      title: "Esta seguro de crear una nueva habitación?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Crear habitación",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        const result = await addBookingHotel(
          props.booking_Id,
          newHotelBooking,
          files
        );

        props.bookingList.push(result);
        dispatch(showLoading(false))
        Swal.fire("Habitación creada correctamente");
        props.onShow(false);

      } else {
        return;
      }
    });

  };

  const countAdultsItem = (quantity) => {
    setCountAdults(quantity);
    newHotelBooking.adultsCapacity = quantity;
  };

  const countChildrenItem = (quantity) => {
    setCountChildren(quantity);
    newHotelBooking.childrenCapacity = quantity;
  };

  const inputChangeListener = (ev) => {
    switch (ev.target.name) {
      case "name":
        newHotelBooking.name = ev.target.value;
        break;
      case "adultsCapacity":
        newHotelBooking.adultsCapacity = ev.target.value;
        break;
      case "childrenCapacity":
        newHotelBooking.childrenCapacity = ev.target.value;
        break;
      case "priceAdult":
        newHotelBooking.priceAdult = ev.target.value;
        break;
      case "priceChildren":
        newHotelBooking.priceChildren = ev.target.value;
        break;
      case "description":
        newHotelBooking.description = ev.target.value;
        break;
      case "checkin":
        newHotelBooking.checkin = ev.target.value + ":00.000";
        break;
      case "checkout":
        newHotelBooking.checkout = ev.target.value + ":00.000";
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onShow(false)}
        dialogClassName="modal-90w "
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Añadir Habitacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="divFormEdit">
              <input
                id="name"
                type="text"
                className="standard-input"
                name="name"
                onChange={inputChangeListener}
                placeholder="Nombre"
              />
            </div>

            <br />
            <div className="divFormEdit">
              <input
                id="name"
                type="text"
                className="standard-input"
                name="description"
                onChange={inputChangeListener}
                placeholder="Descripción"
              />
            </div>
            <br />

            <label>Número de Adultos:</label>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => countAdultsItem(countAdults + 1)}
            >
              +
            </Button>
            <label
              onChange={inputChangeListener}
              name="adultsCapacity"
              style={{ marginLeft: "10px" }}
            >
              {" "}
              {countAdults}{" "}
            </label>

            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => countAdultsItem(countAdults - 1)}
            >
              -
            </Button>
            <br></br>
            <br></br>
            <label>Número de Niños:</label>

            <Button
              style={{ marginLeft: "22px" }}
              onClick={() => countChildrenItem(countChildren + 1)}
            >
              +
            </Button>
            <label
              onChange={inputChangeListener}
              name="childrenCapacity"
              style={{ marginLeft: "10px" }}
            >
              {" "}
              {countChildren}{" "}
            </label>

            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => countChildrenItem(countChildren - 1)}
            >
              -
            </Button>

            <br />
            <br />
            <div className="divFormEdit">
              <input
                id="name"
                type="text"
                name="priceAdult"
                onChange={inputChangeListener}
                className="standard-input"
                placeholder="Precio Niños"
              />
            </div>

            <br />
            <div className="divFormEdit">
              <input
                id="name"
                type="text"
                name="priceChildren"
                onChange={inputChangeListener}
                className="standard-input"
                placeholder="Precio Adultos"
              />
            </div>
            <br />
            <div className="divFormEdit">
              <input
                id="name"
                type="Time"
                name="checkin"
                onChange={inputChangeListener}
                className="standard-input"
                placeholder="CheckIn"
              />
            </div>
            <br />
            <div className="divFormEdit">
              <input
                id="name"
                type="Time"
                name="checkout"
                onChange={inputChangeListener}
                className="standard-input"
                placeholder="CheckOut"
              />
            </div>
            <br></br>
            <div>
              <BusinessServicesList
                business_services={newHotelBooking.services_bases}
                id={0}
                typeServices="new"
              ></BusinessServicesList>
            </div>
            <div className="Image_NewRoom">
              <p>Por favor añada 1 foto de la Habitacion</p>
              <AddImageServices
                id={0}
                typeAsset={"ImagesToBookingHotels"}
                files={files}
              ></AddImageServices>
            </div>
            <br></br>
            <Button onClick={saveBooking} variant="primary">
              Guardar
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default AddNewRoom;
