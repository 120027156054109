import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./RoomServices.css";
import CarouselImages from "../carousel/CarouselRoom";
import Label from "../../ui/Label"


function CardRooms(props) {
  const [showRoomsDetails, setShowRoomsDetails] = useState(false);

  const showRoomsDetailsListener = () => {
    setShowRoomsDetails(true);
  };

  const closeRoomsDetailsListener = () => {
    setShowRoomsDetails(false);
  };

  const setShow = (isShow) => {
    props.onShow(isShow);
  };
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <CarouselImages image={props.room}></CarouselImages>
        </Col>
        <Col md={6}>
          <div className="CardDevice">
            <Card.Title>{props.room.name} </Card.Title>
            <hr/>
            <Card.Text>{props.room.description}</Card.Text>
          </div>


          <div className="CardDevice">
            <h4>Servicios</h4>
              <div style={{display: "flex", padding:"5px"}} >
                {props.room.services_bases.map((item, index) =>
                  <div key={index} >
                    <img width="20px" height="20px" src={item.icon} alt={item.name}></img>
                    <p>{item.name}</p>
                  </div>
                )}
              </div>
          </div>
          <div className="CardDevice">
            <h4>Características</h4>
            <Label className="labelCard" name={"Capacidad de adultos"} value={props.room.adultsCapacity}></Label>
            <Label className="labelCard" name={"Capacidad de niños"} value={props.room.childrenCapacity}></Label>
            <Label className="labelCard" name={"Precio por adulto"} value={props.room.priceAdult}></Label>
            <Label className="labelCard" name={"Precio por niño"} value={props.room.priceChildren}></Label>
            <Label className="labelCard" name={"Checkin"} value={props.room.checkin}></Label>
            <Label className="labelCard" name={"CheckOut"} value={props.room.checkout}></Label>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CardRooms;
