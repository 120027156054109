import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    value: false,
  },
  reducers: {
    showLoading: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { showLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
