import React from 'react'
import './Label.css'

function Label({name, value}) {
  return (
    <div>
        <label className={"name"} >{name}:</label>
        <label className={"value"} >{value}</label>
    </div>
  )
}

export default Label