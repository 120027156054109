/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./Login.css";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import logo from "../../assets/img/logo.jpeg";
import Footer from "../Footer";
import Swal from "sweetalert2";
import Loading from "../../ui/Loading/Loading";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
import { Authenticate, getCompanyByUserId } from "msbookingapiclient";
//redux<

const Login = (props) => {
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [credential, setCredential] = useState({
    username: "",
    password: "",
  });

  const credentialAuth = (ev) => {
    switch (ev.target.name) {
      case "userName":
        credential.username = ev.target.value;
        break;
      case "password":
        credential.password = ev.target.value;
        break;

      default:
        break;
    }
  };

  const authenticate = async (ev) => {
    ev.preventDefault();
    Authenticate(credential.username, credential.password).then((result) => {
      dispatch(showLoading(true));
      if (result === null) {
        setVisible(true);
        Swal.fire("Usuario o contraseña incorrectos");
      } else {
        getCompanyByUserId(result.id).then((result2) => {
          if (result2.data.length > 0) {
            dispatch(showLoading(true));
            localStorage.setItem("MSCOMPANY", JSON.stringify(result2.data[0]));
            props.setUserState(true);
            dispatch(showLoading(false));
            navigate("/");
          } else {
            dispatch(showLoading(false));
            Swal.fire(
              "El usuario no tiene asociada una empresa, comuniquese con el administrador"
            );
          }
        });
      }
    });
  };

  if (loading) {
    dispatch(showLoading(true));
  } else {
    return (
      <>
        <Container as="main" style={{ color: "black" }}>
          <Row className="roomfac vertical-center ">
            <Form>
              <img className="Logo" src={logo} />
              <h2 className="Titulo">M&S CONSULTANT SAS</h2>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  name="userName"
                  onChange={credentialAuth}
                  placeholder="Enter email"
                  autoComplete="username"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={credentialAuth}
                  placeholder="Password"
                  autoComplete="current-password"
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="mb-2 w-auto "
                onClick={authenticate}
              >
                Ingresar
              </Button>
            </Form>
          </Row>
        </Container>
        <Footer></Footer>
      </>
    );
  }
};

export default Login;
