import React from 'react';
import './MediosDePago.css';
import Button from "react-bootstrap/Button";
import Epayco from "../../assets/img/epayco.png"

function ConfiguracionMediosDePago() {
  return (
    <div className='medios-Pago'>
        <img src={Epayco} style={{width: "26%", marginBottom: "10px"}}></img><br></br>
        <label>ID Comercio:</label>
        <input type="number"></input>
            <br/>
        <label>Llave publica:</label>
        <input type="text"></input>
        <br/>
        {/* <label>Llave privada:</label>
        <input type="text"></input>
        <br/> */}
        <Button>Guardar pasarela de pagos</Button>
    </div>
  )
}

export default ConfiguracionMediosDePago