import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import InputDescription from "../components/InputDescription/InputDescription";
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import BusinessServicesList from "../ui/Services/BusinessServicesList";
import Maps from "../ui/Maps/Maps";
import BookingHotelList from "../components/RoomServices/BookingHotelList";
import { useParams } from "react-router-dom";
import {
  getBusinessById,
  getMicroSiteById,
  updateMicroSite,
} from "msbookingapiclient";
import { useEffect } from "react";
import Footer from "../components/Footer";
import RedesSociales from "../components/ConfiguracionRedesSociales/RedesSociales";
import { Button } from "react-bootstrap";
import CarouselBanner from "../ui/CarouselMicrosite/CarouselBanner";
import Loading from "../ui/Loading/Loading";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../store/slices/Loader/loadingReducer";
//redux<

function ConfiguracionesReservaHospedaje(props) {
  const dispatch = useDispatch();
  const [bussinesHotel, setBussinesHotel] = useState(null);
  const [microsite, setMicrosite] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    dispatch(showLoading(true));
    getBusinessById(id).then((result) => {
      setBussinesHotel(result.data);
      getMicroSiteById(result.data.attributes.microsite.data.id).then(
        (micrositeResult) => {
          setMicrosite(micrositeResult.data.data);
          dispatch(showLoading(false));
        }
      );
    });
  }, []);

  const saveMicrositeHotel = () => {
    let dataMicrosite = {
      commercialName:
        bussinesHotel.attributes.microsite.data.attributes.commercialName,
      description:
        bussinesHotel.attributes.microsite.data.attributes.description,
      aboutUs: bussinesHotel.attributes.microsite.data.attributes.aboutUs,
      email1: bussinesHotel.attributes.microsite.data.attributes.email1,
      email2: bussinesHotel.attributes.microsite.data.attributes.email2,
      latitude: bussinesHotel.attributes.microsite.data.attributes.latitude,
      longitude: bussinesHotel.attributes.microsite.data.attributes.longitude,
      googlemaps: bussinesHotel.attributes.microsite.data.attributes.googlemaps,
      phone1: bussinesHotel.attributes.microsite.data.attributes.phone1,
      phone2: bussinesHotel.attributes.microsite.data.attributes.phone2,
      facebookUrl:
        bussinesHotel.attributes.microsite.data.attributes.facebookUrl,
      instagramUrl:
        bussinesHotel.attributes.microsite.data.attributes.instagramUrl,
      whatsappPhone:
        bussinesHotel.attributes.microsite.data.attributes.whatsappPhone,
    };
    dispatch(showLoading(true))
    updateMicroSite(bussinesHotel.attributes.microsite.data.id, dataMicrosite)
      .then((result) => {
        dispatch(showLoading(false))
        Swal.fire("Datos del micrositio actualizados correctamente");
      })
      .catch((error) => {
        dispatch(showLoading(false))
        Swal.fire("Los Datos no se hann guardado correctamente")
      });
  };

  if (bussinesHotel !== null && microsite !== null) {
    return (
      <>
        <Container>
          <Row>
            <NavBarApplication userState={props.setUserState} />
            <Col md={6} className="mt-2 width-100">
              <CarouselBanner
                carousel={microsite.attributes.carousel}
                micrositeId={microsite.id}
              ></CarouselBanner>
            </Col>
            {/* --------------------------MICROSITIO-------------------------------------------------- */}
            <Col md={6}>
              <InputDescription
                microsite={bussinesHotel.attributes.microsite}
              ></InputDescription>
              <RedesSociales
                microsite={bussinesHotel.attributes.microsite}
              ></RedesSociales>
            </Col>
            <Col md={6}>
              <Maps microsite={bussinesHotel.attributes.microsite}></Maps>
            </Col>
            <Col md={6} mt={4}>
              <BusinessServicesList
                business_services={bussinesHotel.attributes.services_bases.data}
                id={bussinesHotel.id}
                typeServices="bussines"
              ></BusinessServicesList>
            </Col>

            <Button
                className="mt-2 mb-2 w-auto mx-auto"
                onClick={saveMicrositeHotel}
              >
                Guardar datos
              </Button>
            {/* -------------------------------------------------------------------- */}
            {/* -------------------------------------BOOKING-------------------------------------------- */}

            <BookingHotelList
              booking_Id={bussinesHotel.attributes.booking.data.id}
            ></BookingHotelList>
            {/* -------------------------------------------------------------------------------------------- */}

            <Footer></Footer>
          </Row>
        </Container>
      </>
    );
  } else {
    return 
  }
}

export default ConfiguracionesReservaHospedaje;
