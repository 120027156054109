import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './carousel.css'
import Loading from '../../ui/Loading/Loading';
import { useEffect } from 'react';

function CarouselImages(props) {
  useEffect(() => {
    if (props.image.carousel === null) {
      props.image.carousel = [];
    }
  }, [props.image.carousel])

  if (props.image.carousel === undefined) {
    props.image.carousel = [];
  }
  return (

    <Carousel>
      {props.image.carousel.map((item, index) =>
        <Carousel.Item key={index}>
          <div className='containerImage'>
            <img
              className="d-block w-100 "
              src={item.url}
              alt={item.name}
            />
          </div>
        </Carousel.Item>

      )}
    </Carousel>

  );
}

export default CarouselImages;