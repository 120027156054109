import React from 'react';
import './RedesSociales.css'

function RedesSociales(props) {  
  const inputNetworksListener = (ev) =>{
      switch (ev.target.name) {
        case "Facebook":
          props.microsite.data.attributes.facebookUrl = ev.target.value;
          break;
          case "WhatsApp":
          props.microsite.data.attributes.whatsappPhone = ev.target.value;
          break;
          case "Instagram":
          props.microsite.data.attributes.instagramUrl = ev.target.value;
          break;
          case "Twitter":
          props.microsite.data.attributes.twitter = ev.target.value;
          break;
      
        default:
          break;
      }
  }

  return (
    <>

      <div className='divContenedorRedesSociales'>
      <div className="facebook">
        <input defaultValue={props.microsite.data.attributes.facebookUrl} name="Facebook" onChange={inputNetworksListener} id="name" type="text" className="standard-input" placeholder="Facebook" />
      </div>

      <div className="Twitter">
        <input defaultValue={props.microsite.data.attributes.instagramUrl} name="Twitter" onChange={inputNetworksListener} id="name" type="text" className="standard-input" placeholder="Twitter" />
      </div>

      </div>
      <div className='divContenedorRedesSociales bt'>
        <div className="Whatsapp">
          <input defaultValue={props.microsite.data.attributes.whatsappPhone} name="WhatsApp" onChange={inputNetworksListener} id="name" type="text" className="standard-input" placeholder="WhatsApp" />
        </div>

        <div className="Instagran">
          <input defaultValue={props.microsite.data.attributes.instagramUrl} name="Instagram" onChange={inputNetworksListener} id="name" type="text" className="standard-input" placeholder="Instagram" />
        </div>
      </div>

    </>
  );
}

export default RedesSociales