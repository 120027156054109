import React from 'react';
import { Navigate } from 'react-router-dom';


const PrivateRoute = ({userState, children}) => {
    if (userState === null) {
        return <Navigate to="/Login" redirectTo="/Login"/>
        
    }

      return children
}


export default PrivateRoute