import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  addImagesToBookingRestaurant,
  addImagesToBookingTours,
  addImagesToBookingDiving,
  addImagesToBookingHotels,
  addImagesToMicrosite,
  deleteFileById,
} from "msbookingapiclient";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AddImageServices.css";
import { useEffect } from "react";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

export default function AddImageServices(props) {
  const [carousel, setCarousel] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.carousel) setCarousel(props.carousel);
  }, [carousel]);

  const deleteImgFromCarousel = (image) => {
    Swal.fire({
      title: "¿Desea borrar esta imagen?",
      text: "Advertencia: Esta eliminación es definitiva, si borra la imagen no podrá visualizarla de nuevo.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        if (image.id === 0) {
          var itemCarousel = carousel.findIndex((x) => x.url === image.url);
          carousel.splice(itemCarousel, 1);
          setCarousel([...carousel]);
          props.files.splice(itemCarousel, 1);
        } else {
          dispatch(showLoading(true));
          deleteFileById(image.id).then((result) => {
            var itemCarousel = carousel.findIndex((x) => x.id === image.id);
            carousel.splice(itemCarousel, 1);
            setCarousel([...carousel]);
            dispatch(showLoading(false));
            Swal.fire("Imagen borrada correctamente");
          });
        }
      }
    });
  };

  const changeInput = (e) => {
    if (e.target.files.length) {
      switch (props.typeAsset) {
        case "ImagesToBookingRestaurant":
          if (props.id > 0) {
            //para una edicion
            dispatch(showLoading(true));
            addImagesToBookingRestaurant(props.id, e.target.files).then(
              (item) => {
                carousel.push(item);
                debugger
                dispatch(showLoading(false));
              }
            );
          } //para una creacion
          else {
            //obtener el archivo
            let filesIn = e.currentTarget.files[0];
            //obtener el url
            let url = URL.createObjectURL(filesIn);
            //adicionar al carrouesl el elemtnto
            carousel.push({ url, id: 0 });
            //adicional al un arreglo Files el file de un props que viene de afuera
            //si es para una edicion el props no vendrá con este arreglo de files
            props.files.push(filesIn);
            setCarousel([...carousel]);
          }

          break;
        case "ImagesToBookingHotels":
          if (props.id > 0) {
            // let files = []
            // files.push(e.target.files[0])
            //para una edicion
            dispatch(showLoading(true));
            addImagesToBookingHotels(props.id, e.target.files).then((item) => {
              carousel.concat(item);
              debugger
              dispatch(showLoading(false));
            });
          } //para una creacion
          else {
            //obtener el archivo
            let filesIn = e.currentTarget.files[0];
            //obtener el url
            let url = URL.createObjectURL(filesIn);
            //adicionar al carrouesl el elemtnto
            carousel.push({ url, id: 0 });
            //adicional al un arreglo Files el file de un props que viene de afuera
            //si es para una edicion el props no vendrá con este arreglo de files
            props.files.push(filesIn);
            setCarousel([...carousel]);
          }
          break;
        case "ImagesToBookingTours":
          if (props.id > 0) {
            //para una edicion
            dispatch(showLoading(true));
            addImagesToBookingTours(props.id, e.target.files).then((item) => {
              carousel.concat(item);
              dispatch(showLoading(false));
            });
          } //para una creacion
          else {
            //obtener el archivo
            let filesIn = e.currentTarget.files[0];
            //obtener el url
            let url = URL.createObjectURL(filesIn);
            //adicionar al carrouesl el elemtnto
            carousel.push({ url, id: 0 });
            //adicional al un arreglo Files el file de un props que viene de afuera
            //si es para una edicion el props no vendrá con este arreglo de files
            props.files.push(filesIn);
            setCarousel([...carousel]);
          }
          break;
        case "ImagesToBookingDivings":
          if (props.id > 0) {
            //para una edicion
            dispatch(showLoading(true));
            addImagesToBookingDiving(props.id, e.target.files).then((item) => {
              carousel.concat(item);
              dispatch(showLoading(false));
            });
          } //para una creacion
          else {
            //obtener el archivo
            let filesIn = e.currentTarget.files[0];
            //obtener el url
            let url = URL.createObjectURL(filesIn);
            //adicionar al carrouesl el elemtnto
            carousel.push({ url, id: 0 });
            //adicional al un arreglo Files el file de un props que viene de afuera
            //si es para una edicion el props no vendrá con este arreglo de files
            props.files.push(filesIn);
            setCarousel([...carousel]);
          }
          break;
        case "ImagesToMicrosite":
          if (props.id > 0) {
            //para una edicion
            dispatch(showLoading(true));
            addImagesToMicrosite(props.id, e.target.files).then((item) => {
              carousel.push(item);
              debugger
              dispatch(showLoading(false));
            });
          } //para una creacion
          else {
            //obtener el archivo
            let filesIn = e.currentTarget.files[0];
            //obtener el url
            let url = URL.createObjectURL(filesIn);
            //adicionar al carrouesl el elemtnto
            carousel.push({ url, id: 0 });
            //adicional al un arreglo Files el file de un props que viene de afuera
            //si es para una edicion el props no vendrá con este arreglo de files
            props.files.push(filesIn);
            setCarousel([...carousel]);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="container-fluid">
      <br></br>
      <div className="row">
        {carousel.map((imagen, index) => {
          return (
            <div className="col-6 col-sm-4 col-lg-3 square" key={index}>
              <div className="content_img">
                <button
                  className="position-absolute btn bg-primary"
                  onClick={(event) => {
                    event.preventDefault();
                    deleteImgFromCarousel(imagen);
                  }}
                >
                  x
                </button>

                <img
                  key={index}
                  alt="carouselImgDetail"
                  src={imagen.url}
                  data-toggle="modal"
                  data-target="#ModalPreViewImg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          );
        })}
      </div>
      <label className="btn btn-primary">
        <span>Seleccionar Fotos </span>
        <input hidden type="file" onChange={changeInput}></input>
      </label>
    </div>
  );
}
