import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddImageServices from "../../ui/AddImageServices/AddImageServices";
import './AddNewBuseo.css';
import Swal from "sweetalert2";
import BusinessServicesList from "../../ui/Services/BusinessServicesList";
import { addBookingDiving } from 'msbookingapiclient';

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function AddNewBuceo(props) {
  const dispatch = useDispatch();
  const [countChildren, setCountChildren] = useState(0);
  const [files, setFiles] = useState([]);
  const [newDivingBooking, setNewDivingBooking] = useState({
    capacity: 0,
    description: "",
    status: true,
    preferenceReservation: "Buceo",
  });


  const countChildrenItem = (quantity) => {
    setCountChildren(quantity);
    newDivingBooking.capacity = quantity;
  };

  const saveBooking = () => {
    Swal.fire({
      title: '¿Esta seguro de crear un nuevo servicio de buseo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Crear buseo'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        addBookingDiving(props.booking_Id, newDivingBooking, files).then(result => {
          props.bookingList.push(newDivingBooking);
          dispatch(showLoading(false))
          Swal.fire('Ha creado un nuevo servicio');
          props.onShow(false)                  
        }).catch((error)=>{
          dispatch(showLoading(false));
          Swal.fire("El servicio no se ha creado correctamente")
        })
      } else {
        return
      }
    })
  }

  const inputChangeListener = (ev) => {
    switch (ev.target.name) {
      case "capacity":
        newDivingBooking.capacity = ev.target.value;
        break;
      case "description":
        newDivingBooking.description = ev.target.value;
        break;
      default:
        break;

    }
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onShow(false)}
        dialogClassName="modal-90w "
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Añadir servicio de buceo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Form'>
            <div className="divFormEdit">
              <input id="name" type="text" name="description" onChange={inputChangeListener} className="standard-input" placeholder="Descripción" />
            </div><br />

              <label>Capacidad:</label>

              <Button
                style={{ marginLeft: "22px" }}
                onClick={() => countChildrenItem(countChildren + 1)}
              >
                +
              </Button>
              <label
                onChange={inputChangeListener}
                name="capacity"
                style={{ marginLeft: "10px" }}
              >
                {" "}
                {countChildren}{" "}
              </label>

              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => countChildrenItem(countChildren - 1)}
              >
                -
              </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveBooking}>Guardar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewBuceo