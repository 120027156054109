import Button from "react-bootstrap/Button";
import "./ServicesRestaurant.css";
import { useState, useEffect } from "react";
import CardRestaurant from "./CardRestaurant";
import AddNewTable from "../NewTable/AddNewTable";
import {
  getBookingById,
  deleteBookingRestaurant,
  cloneBookingRestaurant,
} from "msbookingapiclient";
import EditCardRestaurant from "./EditCardRestaurant";
import { Card, Row } from "react-bootstrap";
import Swal from "sweetalert2";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function TableRestaurantList(props) {
  const dispatch = useDispatch();
  const [itemData, setItemData] = useState({});
  const [showItem, setShowItem] = useState(false);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [showItemEdit, setShowItemEdit] = useState(false);

  const [bookingItem, setBookingItem] = useState({
    id: 0,
    booking_restaurants: [],
  });

  const showItemEditState = (stateBoolean, index) => {
    setItemData(bookingItem.booking_restaurants[index]);
    setShowItemEdit(stateBoolean);
  };
  const closeItemEditListener = (onShow) => {
    setShowItemEdit(onShow);
  };

  function ShowNewItem() {
    setShowItem(true);
  }

  const closeNewItem = () => {
    setShowItem(false);
  };

  const Clone = (id) => {
    Swal.fire({
      title: "¿Seguro de clonar este servicio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Clonar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true));
        cloneBookingRestaurant(props.booking_Id, id)
          .then((result) => {
            let newSlot = result;
            newSlot.attributes.id = result.id;
            bookingItem.booking_restaurants.push(newSlot.attributes);
            dispatch(showLoading(false));
            Swal.fire("La mesa ha sido clonada correctamente");
          })
          .catch((error) => {
            Swal.fire("La mesa no ha sido clonada correctamente");
          });
      }
      return;
    });
  };

  const Delete = (id) => {
    Swal.fire({
      title: "¿Seguro de borrar esta mesa?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true));
        deleteBookingRestaurant(id)
          .then((result) => {
            const index = bookingItem.booking_restaurants.findIndex(
              (item) => item.id === result.data.data.id
            );
            bookingItem.booking_restaurants.splice(index, 1);
            dispatch(showLoading(false));
            Swal.fire("Mesa eliminada correctamente");
          })
          .catch((error) => {
            Swal.fire("La mesa no ha sido eliminada correctamente");
          });
      }
      return;
    });
  };

  useEffect(() => {
    dispatch(showLoading(true));
    getBookingById(props.booking_Id)
      .then((result) => {
        const filterList = result.data.booking_restaurants.filter(
          (item) => item.status === true
        );
        result.data.booking_restaurants = filterList;
        setBookingItem(result.data);
        dispatch(showLoading(false));
      })
      .catch((error) => {
      });
  }, [props.booking_Id]);

  let tables = bookingItem.booking_restaurants.map((item, index) => {
    return (
      <div key={index}>
        <Card style={{ width: "100%" }} className="mb-4">
          <Card.Header className="CardTitle">
            <div className="ToolBox">
              <Button
                onClick={() => showItemEditState(true, index)}
                size="sm"
              >
                <i className="bi bi-pencil-square"></i>Editar
              </Button>
              <Button
                onClick={() => {
                  Clone(item.id);
                }}
                size="sm"
              >
                <i className="bi bi-clipboard-check-fill"></i>Clonar
              </Button>
              <Button
                onClick={() => {
                  Delete(item.id);
                }}
                size="sm"
              >
                <i className="bi bi-trash3"></i>Borrar
              </Button>
            </div>
          </Card.Header>
          <div className="FormCard">
            <Card.Body>
              <CardRestaurant table={item} index={index}></CardRestaurant>
            </Card.Body>
          </div>
        </Card>
      </div>
    );
  });

  return (
    <>
      <div>
        <h3>Configuracion de mesas</h3>

       <Row md={2}>{tables}</Row>
        <AddNewTable
          onShow={setShowItem}
          show={showItem}
          booking_Id={props.booking_Id}
          bookingList={bookingItem.booking_restaurants}
        ></AddNewTable>
        <EditCardRestaurant
          table={itemData}
          show={showItemEdit}
          onShow={closeItemEditListener}
        ></EditCardRestaurant>
        <Button onClick={ShowNewItem} className="w-auto mb-2 mx-auto">
          Añadir mesa
        </Button>
      </div>
    </>
  );
}

export default TableRestaurantList;
