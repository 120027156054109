import React from "react";
import  {Col, Row} from "react-bootstrap";
import { Image } from "react-bootstrap";

function BusinesService(props) {
  return (
      <Col md={4}>
        <label className="label">{props.name}</label>          
      </Col>
  );
}

export default BusinesService;
