import React, { useEffect } from "react";
import { useLottie } from "lottie-react";
import loading from "../../assets/90709-loading-animation.json";
import { Blocks } from "react-loader-spinner";
import "./Loading.css";

//redux>
import { useSelector } from "react-redux";
//redux<

const Loading = () => {
  const showLoading = useSelector((state) => state.showLoading);

  const options = {
    animationData: loading,
    loop: true,
  };

  useEffect(() => {});
  const { View } = useLottie(options);
  return (
    <div
      className={
        showLoading.value === false ? "loading display-none" : "loading"
      }
    >
      <Blocks
        visible={true}
        height="100vh"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
      />
      {/* <div>{View}</div> */}
    </div>
  );
};

export default Loading;
