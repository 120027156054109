import { logDOM } from "@testing-library/react";
import React from "react";
import { Image } from "react-bootstrap";
import "./Servicios.css";

function BusinesServiceSelect(props) {
  const setSelectedItemListener = (ev) => {
    props.service.selected = ev.target.checked;
    if(props.service.selected)
    {
      const index = props.businessService.findIndex(x => x.id === props.service.id);
      props.businessService[index].selected = true;
    }
    else{
      const index = props.businessService.findIndex(x => x.id === props.service.id);
      props.businessService[index].selected = false;
    }
  };
  return (
    <>
      <div style={{ paddingLeft: "10px" }}>
        <span>
        
          <input
            type="checkbox"
            defaultChecked={props.service.selected}
            onChange={setSelectedItemListener}
          ></input>
          <Image
              className="labelSelect"
              width={"15px"}
              src={props.service.attributes.icon}
            ></Image>
          <label className="labelSelect">{props.service.attributes.name}</label>

        </span>
      </div>
    </>
  );
}

export default BusinesServiceSelect;
