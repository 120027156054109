import React from 'react'
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import Footer from "../components/Footer";

function TusComunicaciones() {
  return (
    <div>
      <NavBarApplication></NavBarApplication>

      <Footer></Footer>
    </div>
  )
}

export default TusComunicaciones