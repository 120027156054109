import React from "react";
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import Footer from "../components/Footer";
import ComponentsHome from "../components/ComponentsHome/ComponentsHome";
import "./Home.css";

function Home() {
  return (
    <>
      <NavBarApplication></NavBarApplication>
      <ComponentsHome></ComponentsHome>
      <Footer></Footer>
    </>
  );
}

export default Home;
