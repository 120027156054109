import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./Maps.css";
function Maps(props) {
  const [ubicacion, setUbicacion] = useState(props.microsite.data.attributes.googlemaps)
  const [link, setLink] = useState({
    link:"",
  })
  const ListenerUbicacion = (ev) =>{
    switch (ev.target.name) {
      case "link":
        props.microsite.data.attributes.googlemaps = ev.target.value
        break;
    
      default:
        break;
    }
  }
    const Url = () => { 
      setUbicacion(props.microsite.data.attributes.googlemaps)
   }
 
  return (
    <div className="iframeBox">
      <div className="iframe">
        <iframe src={ubicacion} width="100%" height="350"  loading="lazy"></iframe>
      </div>
      <label>Url:{" "}</label>
      <input type="text" defaultValue={ubicacion} name="link" onChange={ListenerUbicacion}></input>
      <Button onClick={Url} className="mb-2 w-auto mt-2">Establecer mapa</Button>
    </div>
  );
}

export default Maps;
