import React, { useState, useEffect } from 'react'
import NavBarApplication from "../ui/Navbar/NavBarApplication";
import Footer from "../components/Footer";
import ReservasActivas from '../components/ReservasActivas/ReservasActivas';
import { getReservationsByBusinessIdAndDateRange } from 'msbookingapiclient';
import { useParams } from "react-router-dom";
import AgendaPrueba from '../components/ReservasActivas/agendaPrueba';
import Loading from '../ui/Loading/Loading';

function ConfiguracionReservasActivas(props) {
  if(props === null ){
    <Loading></Loading>
  }else{
    return (

      <div>
        <NavBarApplication userState={props.setUserState}></NavBarApplication>
        <ReservasActivas></ReservasActivas>
        {/* <AgendaPrueba></AgendaPrueba> */}
        <Footer></Footer>
      </div>
    )
  }
}

export default ConfiguracionReservasActivas