import React from "react";
import "./InputDays.css";
import { useState } from "react";

function InputDays(props) {

  const editStateImput = e => {

    switch (e.target.name) {
      case "Monday":
        props.days.Monday = e.target.checked
        break;
      case "Tuesday":
        props.days.Tuesday = e.target.checked
        break;
      case "Wednesday":
        props.days.Wednesday = e.target.checked
        break;
      case "Thursday":
        props.days.Thursday = e.target.checked
        break;
      case "Friday":
        props.days.Friday = e.target.checked
        break;
      case "Saturday":
        props.days.Saturday = e.target.checked
        break;
      case "Sunday":
        props.days.Sunday = e.target.checked
        break;

      default:
        break;
    }
  }

  return (


    <>

      <h2>Disponibilidad Semanal</h2><div className="divDays">

        <label className="labelDays">
          <input type="checkbox" name="Monday" onChange={editStateImput} defaultChecked={props.days.Monday} ></input>
          <span className="spanDays"></span>
          <label className="labelDays">Lunes</label>
        </label>

        <label className="labelDays">
          <input type="checkbox" name="Tuesday" onChange={editStateImput} defaultChecked={props.days.Tuesday}></input>
          <span className="spanDays"></span>
          <label className="labelDays">Martes</label>
        </label>
        <label className="labelDays">
          <input type="checkbox" name="Wednesday" onChange={editStateImput} defaultChecked={props.days.Wednesday} ></input>
          <span className="spanDays"></span>
          <label className="labelDays">Miercoles</label>
        </label>
        <label className="labelDays">
          <input type="checkbox" name="Thursday" onChange={editStateImput} defaultChecked={props.days.Thursday}></input>
          <span className="spanDays"></span>
          <label className="labelDays">Jueves</label>
        </label>
        <label className="labelDays">
          <input type="checkbox" name="Friday" onChange={editStateImput} defaultChecked={props.days.Friday}></input>
          <span className="spanDays"></span>
          <label className="labelDays">Viernes</label>
        </label>
        <label className="labelDays">
          <input type="checkbox" name="Saturday" onChange={editStateImput} defaultChecked={props.days.Saturday}></input>
          <span className="spanDays"></span>
          <label className="labelDays">Sabado</label>
        </label>
        <label className="labelDays">
          <input type="checkbox" name="Sunday" onChange={editStateImput} defaultChecked={props.days.Sunday}></input>
          <span className="spanDays"></span>
          <label className="labelDays">Domingo</label>
        </label>
      </div>
    </>
  );
}
export default InputDays;
