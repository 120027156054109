import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, InputGroup, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import "./MenuBasic.css";

const MenuBasic = ({ setMenuValue, menuValue }) => {
  const [flag, setFlag] = useState(true);
  const [menuInterno, setMenuInterno] = useState(JSON.parse(menuValue));

  useEffect(() => {}, [flag]);

  const deleteItem = (index) => {
    Swal.fire({
      title: "Esta seguro de borrar este menú?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        menuInterno.splice(index, 1);
        setFlag(!flag);
        setMenuValue(JSON.stringify(menuInterno));
      }
    });
  };
  const addItem = () => {
    menuInterno.push({
      item: "",
      description: "",
    });
    setFlag(!flag);
    setMenuValue(JSON.stringify(menuInterno));
  };

  const handleChange = (event) => {
    let name = event.target.name.split("_")[0];
    let index = event.target.name.split("_")[1];

    switch (name) {
      case "item":
        menuInterno[index].item = event.target.value;
        break;
      case "description":
        menuInterno[index].description = event.target.value;
        break;
      default:
        break;
    }

    setMenuValue(JSON.stringify(menuInterno));
  };
  return (
    <Container className="menuBasicContainer">
      <Button
        variant="warning"
        className="mb-2 w-auto "
        onClick={() => {
          addItem();
        }}
      >
        + Nuevo menu
      </Button>
      {menuInterno.map((item, index) => {
        return (
          <Row key={index} className="menuBasicItem">
            <Col xs={12}>
              <InputGroup size="sm" className="mt-3">
                <InputGroup.Text>Menú</InputGroup.Text>
                <Form.Control
                  name={"item_" + index}
                  defaultValue={item.item}
                  placeholder="Nombre menu"
                  onChange={handleChange}
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                />
                <Button
                  variant="danger"
                  onClick={() => {
                    deleteItem(index);
                  }}
                >
                  -
                </Button>
              </InputGroup>
            </Col>
            <Col xs={12}>
              <InputGroup>
                <InputGroup.Text>Datos adicionales</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  aria-label="With textarea"
                  defaultValue={item.description}
                  placeholder="precios, descripcion, etc."
                  onChange={handleChange}
                  name={"description_" + index}
                />
              </InputGroup>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default MenuBasic;
