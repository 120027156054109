import React from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';
import CarouselImages from '../carousel/CarouselRoom'
import './TouresServices.css'

function CardToures(props) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={6}>
            <CarouselImages image={props.tours}></CarouselImages>
          </Col>
          <Col md={6}>
            <div>
              <Card.Text>Nombre: <br/>{props.tours.name}</Card.Text>
              <Card.Text>Descripción: <br/>{props.tours.description}</Card.Text>
            </div>
            <div>
              <h4>Características</h4>
              <label>Capacidad: {props.tours.capacity}</label><br/>
              <label>Precio: {props.tours.price}</label>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CardToures