import React from "react";
import "./ServicesRestaurant.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Card, Container} from "react-bootstrap";
import Label from "../../ui/Label";

function CardRestaurant(props) {
  return (
    <Container fluid>
      <Card.Title> </Card.Title>
      <Card.Body>
        <div className="CardDivice">
          <label className="mx-center">Mesa numero: {props.index + 1}</label>
          <label
            name={"Capacidad"}
          >Capacidad: {props.table.capacity}</label>
        </div>
      </Card.Body>
    </Container>
  );
}

export default CardRestaurant;
