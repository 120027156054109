import { createSlice } from "@reduxjs/toolkit";

const companyDefault ={
    "data": {
      "id": 0,
      "attributes": {
        "uid": "string",
        "name": "string",
        "nit": "string",
        "cover": {
          "data": {
            "id": 0,
            "attributes": {
              "name": "string",
              "alternativeText": "string",
              "caption": "string",
              "width": 0,
              "height": 0,
              "formats": "string",
              "hash": "string",
              "ext": "string",
              "mime": "string",
              "size": 0,
              "url": "string",
              "previewUrl": "string",
              "provider": "string",
              "provider_metadata": "string",
              "related": {
                "data": [
                  {
                    "id": 0,
                    "attributes": {}
                  }
                ]
              },
              "folder": {
                "data": {
                  "id": 0,
                  "attributes": {
                    "name": "string",
                    "pathId": 0,
                    "parent": {
                      "data": {
                        "id": 0,
                        "attributes": {}
                      }
                    },
                    "children": {
                      "data": [
                        {
                          "id": 0,
                          "attributes": {}
                        }
                      ]
                    },
                    "files": {
                      "data": [
                        {
                          "id": 0,
                          "attributes": {
                            "name": "string",
                            "alternativeText": "string",
                            "caption": "string",
                            "width": 0,
                            "height": 0,
                            "formats": "string",
                            "hash": "string",
                            "ext": "string",
                            "mime": "string",
                            "size": 0,
                            "url": "string",
                            "previewUrl": "string",
                            "provider": "string",
                            "provider_metadata": "string",
                            "related": {
                              "data": [
                                {
                                  "id": 0,
                                  "attributes": {}
                                }
                              ]
                            },
                            "folder": {
                              "data": {
                                "id": 0,
                                "attributes": {}
                              }
                            },
                            "folderPath": "string",
                            "createdAt": "2022-10-03T22:39:50.512Z",
                            "updatedAt": "2022-10-03T22:39:50.512Z",
                            "createdBy": {
                              "data": {
                                "id": 0,
                                "attributes": {
                                  "firstname": "string",
                                  "lastname": "string",
                                  "username": "string",
                                  "email": "user@example.com",
                                  "resetPasswordToken": "string",
                                  "registrationToken": "string",
                                  "isActive": true,
                                  "roles": {
                                    "data": [
                                      {
                                        "id": 0,
                                        "attributes": {
                                          "name": "string",
                                          "code": "string",
                                          "description": "string",
                                          "users": {
                                            "data": [
                                              {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            ]
                                          },
                                          "permissions": {
                                            "data": [
                                              {
                                                "id": 0,
                                                "attributes": {
                                                  "action": "string",
                                                  "subject": "string",
                                                  "properties": "string",
                                                  "conditions": "string",
                                                  "role": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  },
                                                  "createdAt": "2022-10-03T22:39:50.512Z",
                                                  "updatedAt": "2022-10-03T22:39:50.512Z",
                                                  "createdBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  },
                                                  "updatedBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  }
                                                }
                                              }
                                            ]
                                          },
                                          "createdAt": "2022-10-03T22:39:50.512Z",
                                          "updatedAt": "2022-10-03T22:39:50.512Z",
                                          "createdBy": {
                                            "data": {
                                              "id": 0,
                                              "attributes": {}
                                            }
                                          },
                                          "updatedBy": {
                                            "data": {
                                              "id": 0,
                                              "attributes": {}
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  "blocked": true,
                                  "preferedLanguage": "string",
                                  "createdAt": "2022-10-03T22:39:50.512Z",
                                  "updatedAt": "2022-10-03T22:39:50.512Z",
                                  "createdBy": {
                                    "data": {
                                      "id": 0,
                                      "attributes": {}
                                    }
                                  },
                                  "updatedBy": {
                                    "data": {
                                      "id": 0,
                                      "attributes": {}
                                    }
                                  }
                                }
                              }
                            },
                            "updatedBy": {
                              "data": {
                                "id": 0,
                                "attributes": {}
                              }
                            }
                          }
                        }
                      ]
                    },
                    "path": "string",
                    "createdAt": "2022-10-03T22:39:50.512Z",
                    "updatedAt": "2022-10-03T22:39:50.512Z",
                    "createdBy": {
                      "data": {
                        "id": 0,
                        "attributes": {}
                      }
                    },
                    "updatedBy": {
                      "data": {
                        "id": 0,
                        "attributes": {}
                      }
                    }
                  }
                }
              },
              "folderPath": "string",
              "createdAt": "2022-10-03T22:39:50.512Z",
              "updatedAt": "2022-10-03T22:39:50.512Z",
              "createdBy": {
                "data": {
                  "id": 0,
                  "attributes": {}
                }
              },
              "updatedBy": {
                "data": {
                  "id": 0,
                  "attributes": {}
                }
              }
            }
          }
        },
        "avatar": {
          "data": {
            "id": 0,
            "attributes": {
              "name": "string",
              "alternativeText": "string",
              "caption": "string",
              "width": 0,
              "height": 0,
              "formats": "string",
              "hash": "string",
              "ext": "string",
              "mime": "string",
              "size": 0,
              "url": "string",
              "previewUrl": "string",
              "provider": "string",
              "provider_metadata": "string",
              "related": {
                "data": [
                  {
                    "id": 0,
                    "attributes": {}
                  }
                ]
              },
              "folder": {
                "data": {
                  "id": 0,
                  "attributes": {
                    "name": "string",
                    "pathId": 0,
                    "parent": {
                      "data": {
                        "id": 0,
                        "attributes": {}
                      }
                    },
                    "children": {
                      "data": [
                        {
                          "id": 0,
                          "attributes": {}
                        }
                      ]
                    },
                    "files": {
                      "data": [
                        {
                          "id": 0,
                          "attributes": {
                            "name": "string",
                            "alternativeText": "string",
                            "caption": "string",
                            "width": 0,
                            "height": 0,
                            "formats": "string",
                            "hash": "string",
                            "ext": "string",
                            "mime": "string",
                            "size": 0,
                            "url": "string",
                            "previewUrl": "string",
                            "provider": "string",
                            "provider_metadata": "string",
                            "related": {
                              "data": [
                                {
                                  "id": 0,
                                  "attributes": {}
                                }
                              ]
                            },
                            "folder": {
                              "data": {
                                "id": 0,
                                "attributes": {}
                              }
                            },
                            "folderPath": "string",
                            "createdAt": "2022-10-03T22:39:50.513Z",
                            "updatedAt": "2022-10-03T22:39:50.513Z",
                            "createdBy": {
                              "data": {
                                "id": 0,
                                "attributes": {
                                  "firstname": "string",
                                  "lastname": "string",
                                  "username": "string",
                                  "email": "user@example.com",
                                  "resetPasswordToken": "string",
                                  "registrationToken": "string",
                                  "isActive": true,
                                  "roles": {
                                    "data": [
                                      {
                                        "id": 0,
                                        "attributes": {
                                          "name": "string",
                                          "code": "string",
                                          "description": "string",
                                          "users": {
                                            "data": [
                                              {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            ]
                                          },
                                          "permissions": {
                                            "data": [
                                              {
                                                "id": 0,
                                                "attributes": {
                                                  "action": "string",
                                                  "subject": "string",
                                                  "properties": "string",
                                                  "conditions": "string",
                                                  "role": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  },
                                                  "createdAt": "2022-10-03T22:39:50.513Z",
                                                  "updatedAt": "2022-10-03T22:39:50.513Z",
                                                  "createdBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  },
                                                  "updatedBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  }
                                                }
                                              }
                                            ]
                                          },
                                          "createdAt": "2022-10-03T22:39:50.513Z",
                                          "updatedAt": "2022-10-03T22:39:50.513Z",
                                          "createdBy": {
                                            "data": {
                                              "id": 0,
                                              "attributes": {}
                                            }
                                          },
                                          "updatedBy": {
                                            "data": {
                                              "id": 0,
                                              "attributes": {}
                                            }
                                          }
                                        }
                                      }
                                    ]
                                  },
                                  "blocked": true,
                                  "preferedLanguage": "string",
                                  "createdAt": "2022-10-03T22:39:50.513Z",
                                  "updatedAt": "2022-10-03T22:39:50.513Z",
                                  "createdBy": {
                                    "data": {
                                      "id": 0,
                                      "attributes": {}
                                    }
                                  },
                                  "updatedBy": {
                                    "data": {
                                      "id": 0,
                                      "attributes": {}
                                    }
                                  }
                                }
                              }
                            },
                            "updatedBy": {
                              "data": {
                                "id": 0,
                                "attributes": {}
                              }
                            }
                          }
                        }
                      ]
                    },
                    "path": "string",
                    "createdAt": "2022-10-03T22:39:50.513Z",
                    "updatedAt": "2022-10-03T22:39:50.513Z",
                    "createdBy": {
                      "data": {
                        "id": 0,
                        "attributes": {}
                      }
                    },
                    "updatedBy": {
                      "data": {
                        "id": 0,
                        "attributes": {}
                      }
                    }
                  }
                }
              },
              "folderPath": "string",
              "createdAt": "2022-10-03T22:39:50.513Z",
              "updatedAt": "2022-10-03T22:39:50.513Z",
              "createdBy": {
                "data": {
                  "id": 0,
                  "attributes": {}
                }
              },
              "updatedBy": {
                "data": {
                  "id": 0,
                  "attributes": {}
                }
              }
            }
          }
        },
        "enabled": true,
        "businesses": {
          "data": [
            {
              "id": 0,
              "attributes": {
                "uid": "string",
                "microsite": {
                  "data": {
                    "id": 0,
                    "attributes": {
                      "commercialName": "string",
                      "description": "string",
                      "aboutUs": "string",
                      "email1": "user@example.com",
                      "email2": "user@example.com",
                      "address": "string",
                      "latitude": 0,
                      "longitude": 0,
                      "phone1": "string",
                      "phone2": "string",
                      "carousel": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "name": "string",
                              "alternativeText": "string",
                              "caption": "string",
                              "width": 0,
                              "height": 0,
                              "formats": "string",
                              "hash": "string",
                              "ext": "string",
                              "mime": "string",
                              "size": 0,
                              "url": "string",
                              "previewUrl": "string",
                              "provider": "string",
                              "provider_metadata": "string",
                              "related": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {}
                                  }
                                ]
                              },
                              "folder": {
                                "data": {
                                  "id": 0,
                                  "attributes": {
                                    "name": "string",
                                    "pathId": 0,
                                    "parent": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    },
                                    "children": {
                                      "data": [
                                        {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      ]
                                    },
                                    "files": {
                                      "data": [
                                        {
                                          "id": 0,
                                          "attributes": {
                                            "name": "string",
                                            "alternativeText": "string",
                                            "caption": "string",
                                            "width": 0,
                                            "height": 0,
                                            "formats": "string",
                                            "hash": "string",
                                            "ext": "string",
                                            "mime": "string",
                                            "size": 0,
                                            "url": "string",
                                            "previewUrl": "string",
                                            "provider": "string",
                                            "provider_metadata": "string",
                                            "related": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {}
                                                }
                                              ]
                                            },
                                            "folder": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "folderPath": "string",
                                            "createdAt": "2022-10-03T22:39:50.513Z",
                                            "updatedAt": "2022-10-03T22:39:50.513Z",
                                            "createdBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {
                                                  "firstname": "string",
                                                  "lastname": "string",
                                                  "username": "string",
                                                  "email": "user@example.com",
                                                  "resetPasswordToken": "string",
                                                  "registrationToken": "string",
                                                  "isActive": true,
                                                  "roles": {
                                                    "data": [
                                                      {
                                                        "id": 0,
                                                        "attributes": {
                                                          "name": "string",
                                                          "code": "string",
                                                          "description": "string",
                                                          "users": {
                                                            "data": [
                                                              {
                                                                "id": 0,
                                                                "attributes": {}
                                                              }
                                                            ]
                                                          },
                                                          "permissions": {
                                                            "data": [
                                                              {
                                                                "id": 0,
                                                                "attributes": {
                                                                  "action": "string",
                                                                  "subject": "string",
                                                                  "properties": "string",
                                                                  "conditions": "string",
                                                                  "role": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  },
                                                                  "createdAt": "2022-10-03T22:39:50.513Z",
                                                                  "updatedAt": "2022-10-03T22:39:50.513Z",
                                                                  "createdBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  },
                                                                  "updatedBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          "createdAt": "2022-10-03T22:39:50.513Z",
                                                          "updatedAt": "2022-10-03T22:39:50.513Z",
                                                          "createdBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          },
                                                          "updatedBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          }
                                                        }
                                                      }
                                                    ]
                                                  },
                                                  "blocked": true,
                                                  "preferedLanguage": "string",
                                                  "createdAt": "2022-10-03T22:39:50.513Z",
                                                  "updatedAt": "2022-10-03T22:39:50.513Z",
                                                  "createdBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  },
                                                  "updatedBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  }
                                                }
                                              }
                                            },
                                            "updatedBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    "path": "string",
                                    "createdAt": "2022-10-03T22:39:50.513Z",
                                    "updatedAt": "2022-10-03T22:39:50.513Z",
                                    "createdBy": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    },
                                    "updatedBy": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    }
                                  }
                                }
                              },
                              "folderPath": "string",
                              "createdAt": "2022-10-03T22:39:50.513Z",
                              "updatedAt": "2022-10-03T22:39:50.513Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "facebookUrl": "string",
                      "instagramUrl": "string",
                      "whatsappPhone": "string",
                      "createdAt": "2022-10-03T22:39:50.514Z",
                      "updatedAt": "2022-10-03T22:39:50.514Z",
                      "createdBy": {
                        "data": {
                          "id": 0,
                          "attributes": {
                            "firstname": "string",
                            "lastname": "string",
                            "username": "string",
                            "email": "user@example.com",
                            "resetPasswordToken": "string",
                            "registrationToken": "string",
                            "isActive": true,
                            "roles": {
                              "data": [
                                {
                                  "id": 0,
                                  "attributes": {
                                    "name": "string",
                                    "code": "string",
                                    "description": "string",
                                    "users": {
                                      "data": [
                                        {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      ]
                                    },
                                    "permissions": {
                                      "data": [
                                        {
                                          "id": 0,
                                          "attributes": {
                                            "action": "string",
                                            "subject": "string",
                                            "properties": "string",
                                            "conditions": "string",
                                            "role": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "createdAt": "2022-10-03T22:39:50.514Z",
                                            "updatedAt": "2022-10-03T22:39:50.514Z",
                                            "createdBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "updatedBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    "createdAt": "2022-10-03T22:39:50.514Z",
                                    "updatedAt": "2022-10-03T22:39:50.514Z",
                                    "createdBy": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    },
                                    "updatedBy": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    }
                                  }
                                }
                              ]
                            },
                            "blocked": true,
                            "preferedLanguage": "string",
                            "createdAt": "2022-10-03T22:39:50.514Z",
                            "updatedAt": "2022-10-03T22:39:50.514Z",
                            "createdBy": {
                              "data": {
                                "id": 0,
                                "attributes": {}
                              }
                            },
                            "updatedBy": {
                              "data": {
                                "id": 0,
                                "attributes": {}
                              }
                            }
                          }
                        }
                      },
                      "updatedBy": {
                        "data": {
                          "id": 0,
                          "attributes": {}
                        }
                      }
                    }
                  }
                },
                "booking": {
                  "data": {
                    "id": 0,
                    "attributes": {
                      "uid": "string",
                      "business_services": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "uid": "string",
                              "urlLogo": "string",
                              "name": "string",
                              "notes": "string",
                              "createdAt": "2022-10-03T22:39:50.514Z",
                              "updatedAt": "2022-10-03T22:39:50.514Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "carousel": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "name": "string",
                              "alternativeText": "string",
                              "caption": "string",
                              "width": 0,
                              "height": 0,
                              "formats": "string",
                              "hash": "string",
                              "ext": "string",
                              "mime": "string",
                              "size": 0,
                              "url": "string",
                              "previewUrl": "string",
                              "provider": "string",
                              "provider_metadata": "string",
                              "related": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {}
                                  }
                                ]
                              },
                              "folder": {
                                "data": {
                                  "id": 0,
                                  "attributes": {
                                    "name": "string",
                                    "pathId": 0,
                                    "parent": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    },
                                    "children": {
                                      "data": [
                                        {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      ]
                                    },
                                    "files": {
                                      "data": [
                                        {
                                          "id": 0,
                                          "attributes": {
                                            "name": "string",
                                            "alternativeText": "string",
                                            "caption": "string",
                                            "width": 0,
                                            "height": 0,
                                            "formats": "string",
                                            "hash": "string",
                                            "ext": "string",
                                            "mime": "string",
                                            "size": 0,
                                            "url": "string",
                                            "previewUrl": "string",
                                            "provider": "string",
                                            "provider_metadata": "string",
                                            "related": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {}
                                                }
                                              ]
                                            },
                                            "folder": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "folderPath": "string",
                                            "createdAt": "2022-10-03T22:39:50.514Z",
                                            "updatedAt": "2022-10-03T22:39:50.514Z",
                                            "createdBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {
                                                  "firstname": "string",
                                                  "lastname": "string",
                                                  "username": "string",
                                                  "email": "user@example.com",
                                                  "resetPasswordToken": "string",
                                                  "registrationToken": "string",
                                                  "isActive": true,
                                                  "roles": {
                                                    "data": [
                                                      {
                                                        "id": 0,
                                                        "attributes": {
                                                          "name": "string",
                                                          "code": "string",
                                                          "description": "string",
                                                          "users": {
                                                            "data": [
                                                              {
                                                                "id": 0,
                                                                "attributes": {}
                                                              }
                                                            ]
                                                          },
                                                          "permissions": {
                                                            "data": [
                                                              {
                                                                "id": 0,
                                                                "attributes": {
                                                                  "action": "string",
                                                                  "subject": "string",
                                                                  "properties": "string",
                                                                  "conditions": "string",
                                                                  "role": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  },
                                                                  "createdAt": "2022-10-03T22:39:50.514Z",
                                                                  "updatedAt": "2022-10-03T22:39:50.514Z",
                                                                  "createdBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  },
                                                                  "updatedBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          "createdAt": "2022-10-03T22:39:50.514Z",
                                                          "updatedAt": "2022-10-03T22:39:50.514Z",
                                                          "createdBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          },
                                                          "updatedBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          }
                                                        }
                                                      }
                                                    ]
                                                  },
                                                  "blocked": true,
                                                  "preferedLanguage": "string",
                                                  "createdAt": "2022-10-03T22:39:50.514Z",
                                                  "updatedAt": "2022-10-03T22:39:50.514Z",
                                                  "createdBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  },
                                                  "updatedBy": {
                                                    "data": {
                                                      "id": 0,
                                                      "attributes": {}
                                                    }
                                                  }
                                                }
                                              }
                                            },
                                            "updatedBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            }
                                          }
                                        }
                                      ]
                                    },
                                    "path": "string",
                                    "createdAt": "2022-10-03T22:39:50.514Z",
                                    "updatedAt": "2022-10-03T22:39:50.514Z",
                                    "createdBy": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    },
                                    "updatedBy": {
                                      "data": {
                                        "id": 0,
                                        "attributes": {}
                                      }
                                    }
                                  }
                                }
                              },
                              "folderPath": "string",
                              "createdAt": "2022-10-03T22:39:50.514Z",
                              "updatedAt": "2022-10-03T22:39:50.514Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "cancelPolicy": "string",
                      "booking_hotels": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "uid": "string",
                              "check": [
                                {
                                  "id": 0,
                                  "checkin": "12:54.000",
                                  "checkout": "12:54.000"
                                }
                              ],
                              "adultsCapacity": 0,
                              "childrenCapacity": 0,
                              "priceAdult": 0,
                              "priceChildren": 0,
                              "preferenceReservation": "Turismo",
                              "description": "string",
                              "services_bases": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {
                                      "uid": "string",
                                      "name": "string",
                                      "icon": "string",
                                      "createdAt": "2022-10-03T22:39:50.514Z",
                                      "updatedAt": "2022-10-03T22:39:50.515Z",
                                      "createdBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      },
                                      "updatedBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      }
                                    }
                                  }
                                ]
                              },
                              "status": true,
                              "carousel": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {
                                      "name": "string",
                                      "alternativeText": "string",
                                      "caption": "string",
                                      "width": 0,
                                      "height": 0,
                                      "formats": "string",
                                      "hash": "string",
                                      "ext": "string",
                                      "mime": "string",
                                      "size": 0,
                                      "url": "string",
                                      "previewUrl": "string",
                                      "provider": "string",
                                      "provider_metadata": "string",
                                      "related": {
                                        "data": [
                                          {
                                            "id": 0,
                                            "attributes": {}
                                          }
                                        ]
                                      },
                                      "folder": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {
                                            "name": "string",
                                            "pathId": 0,
                                            "parent": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "children": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {}
                                                }
                                              ]
                                            },
                                            "files": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {
                                                    "name": "string",
                                                    "alternativeText": "string",
                                                    "caption": "string",
                                                    "width": 0,
                                                    "height": 0,
                                                    "formats": "string",
                                                    "hash": "string",
                                                    "ext": "string",
                                                    "mime": "string",
                                                    "size": 0,
                                                    "url": "string",
                                                    "previewUrl": "string",
                                                    "provider": "string",
                                                    "provider_metadata": "string",
                                                    "related": {
                                                      "data": [
                                                        {
                                                          "id": 0,
                                                          "attributes": {}
                                                        }
                                                      ]
                                                    },
                                                    "folder": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {}
                                                      }
                                                    },
                                                    "folderPath": "string",
                                                    "createdAt": "2022-10-03T22:39:50.515Z",
                                                    "updatedAt": "2022-10-03T22:39:50.515Z",
                                                    "createdBy": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {
                                                          "firstname": "string",
                                                          "lastname": "string",
                                                          "username": "string",
                                                          "email": "user@example.com",
                                                          "resetPasswordToken": "string",
                                                          "registrationToken": "string",
                                                          "isActive": true,
                                                          "roles": {
                                                            "data": [
                                                              {
                                                                "id": 0,
                                                                "attributes": {
                                                                  "name": "string",
                                                                  "code": "string",
                                                                  "description": "string",
                                                                  "users": {
                                                                    "data": [
                                                                      {
                                                                        "id": 0,
                                                                        "attributes": {}
                                                                      }
                                                                    ]
                                                                  },
                                                                  "permissions": {
                                                                    "data": [
                                                                      {
                                                                        "id": 0,
                                                                        "attributes": {
                                                                          "action": "string",
                                                                          "subject": "string",
                                                                          "properties": "string",
                                                                          "conditions": "string",
                                                                          "role": {
                                                                            "data": {
                                                                              "id": 0,
                                                                              "attributes": {}
                                                                            }
                                                                          },
                                                                          "createdAt": "2022-10-03T22:39:50.515Z",
                                                                          "updatedAt": "2022-10-03T22:39:50.515Z",
                                                                          "createdBy": {
                                                                            "data": {
                                                                              "id": 0,
                                                                              "attributes": {}
                                                                            }
                                                                          },
                                                                          "updatedBy": {
                                                                            "data": {
                                                                              "id": 0,
                                                                              "attributes": {}
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    ]
                                                                  },
                                                                  "createdAt": "2022-10-03T22:39:50.515Z",
                                                                  "updatedAt": "2022-10-03T22:39:50.515Z",
                                                                  "createdBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  },
                                                                  "updatedBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          "blocked": true,
                                                          "preferedLanguage": "string",
                                                          "createdAt": "2022-10-03T22:39:50.515Z",
                                                          "updatedAt": "2022-10-03T22:39:50.515Z",
                                                          "createdBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          },
                                                          "updatedBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          }
                                                        }
                                                      }
                                                    },
                                                    "updatedBy": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {}
                                                      }
                                                    }
                                                  }
                                                }
                                              ]
                                            },
                                            "path": "string",
                                            "createdAt": "2022-10-03T22:39:50.515Z",
                                            "updatedAt": "2022-10-03T22:39:50.515Z",
                                            "createdBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "updatedBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            }
                                          }
                                        }
                                      },
                                      "folderPath": "string",
                                      "createdAt": "2022-10-03T22:39:50.515Z",
                                      "updatedAt": "2022-10-03T22:39:50.515Z",
                                      "createdBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      },
                                      "updatedBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      }
                                    }
                                  }
                                ]
                              },
                              "createdAt": "2022-10-03T22:39:50.515Z",
                              "updatedAt": "2022-10-03T22:39:50.515Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "booking_restaurants": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "uid": "string",
                              "adultsCapacity": 0,
                              "childrenCapacity": 0,
                              "description": "string",
                              "services_bases": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {}
                                  }
                                ]
                              },
                              "priceAdult": 0,
                              "priceChildren": 0,
                              "status": true,
                              "carousel": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {
                                      "name": "string",
                                      "alternativeText": "string",
                                      "caption": "string",
                                      "width": 0,
                                      "height": 0,
                                      "formats": "string",
                                      "hash": "string",
                                      "ext": "string",
                                      "mime": "string",
                                      "size": 0,
                                      "url": "string",
                                      "previewUrl": "string",
                                      "provider": "string",
                                      "provider_metadata": "string",
                                      "related": {
                                        "data": [
                                          {
                                            "id": 0,
                                            "attributes": {}
                                          }
                                        ]
                                      },
                                      "folder": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {
                                            "name": "string",
                                            "pathId": 0,
                                            "parent": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "children": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {}
                                                }
                                              ]
                                            },
                                            "files": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {
                                                    "name": "string",
                                                    "alternativeText": "string",
                                                    "caption": "string",
                                                    "width": 0,
                                                    "height": 0,
                                                    "formats": "string",
                                                    "hash": "string",
                                                    "ext": "string",
                                                    "mime": "string",
                                                    "size": 0,
                                                    "url": "string",
                                                    "previewUrl": "string",
                                                    "provider": "string",
                                                    "provider_metadata": "string",
                                                    "related": {
                                                      "data": [
                                                        {
                                                          "id": 0,
                                                          "attributes": {}
                                                        }
                                                      ]
                                                    },
                                                    "folder": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {}
                                                      }
                                                    },
                                                    "folderPath": "string",
                                                    "createdAt": "2022-10-03T22:39:50.515Z",
                                                    "updatedAt": "2022-10-03T22:39:50.515Z",
                                                    "createdBy": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {
                                                          "firstname": "string",
                                                          "lastname": "string",
                                                          "username": "string",
                                                          "email": "user@example.com",
                                                          "resetPasswordToken": "string",
                                                          "registrationToken": "string",
                                                          "isActive": true,
                                                          "roles": {
                                                            "data": [
                                                              {
                                                                "id": 0,
                                                                "attributes": {
                                                                  "name": "string",
                                                                  "code": "string",
                                                                  "description": "string",
                                                                  "users": {
                                                                    "data": [
                                                                      {
                                                                        "id": 0,
                                                                        "attributes": {}
                                                                      }
                                                                    ]
                                                                  },
                                                                  "permissions": {
                                                                    "data": [
                                                                      {
                                                                        "id": 0,
                                                                        "attributes": {
                                                                          "action": "string",
                                                                          "subject": "string",
                                                                          "properties": "string",
                                                                          "conditions": "string",
                                                                          "role": {
                                                                            "data": {
                                                                              "id": 0,
                                                                              "attributes": {}
                                                                            }
                                                                          },
                                                                          "createdAt": "2022-10-03T22:39:50.515Z",
                                                                          "updatedAt": "2022-10-03T22:39:50.515Z",
                                                                          "createdBy": {
                                                                            "data": {
                                                                              "id": 0,
                                                                              "attributes": {}
                                                                            }
                                                                          },
                                                                          "updatedBy": {
                                                                            "data": {
                                                                              "id": 0,
                                                                              "attributes": {}
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    ]
                                                                  },
                                                                  "createdAt": "2022-10-03T22:39:50.515Z",
                                                                  "updatedAt": "2022-10-03T22:39:50.516Z",
                                                                  "createdBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  },
                                                                  "updatedBy": {
                                                                    "data": {
                                                                      "id": 0,
                                                                      "attributes": {}
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            ]
                                                          },
                                                          "blocked": true,
                                                          "preferedLanguage": "string",
                                                          "createdAt": "2022-10-03T22:39:50.516Z",
                                                          "updatedAt": "2022-10-03T22:39:50.516Z",
                                                          "createdBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          },
                                                          "updatedBy": {
                                                            "data": {
                                                              "id": 0,
                                                              "attributes": {}
                                                            }
                                                          }
                                                        }
                                                      }
                                                    },
                                                    "updatedBy": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {}
                                                      }
                                                    }
                                                  }
                                                }
                                              ]
                                            },
                                            "path": "string",
                                            "createdAt": "2022-10-03T22:39:50.516Z",
                                            "updatedAt": "2022-10-03T22:39:50.516Z",
                                            "createdBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "updatedBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            }
                                          }
                                        }
                                      },
                                      "folderPath": "string",
                                      "createdAt": "2022-10-03T22:39:50.516Z",
                                      "updatedAt": "2022-10-03T22:39:50.516Z",
                                      "createdBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      },
                                      "updatedBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      }
                                    }
                                  }
                                ]
                              },
                              "Monday": true,
                              "Tuesday": true,
                              "Wednesday": true,
                              "Thursday": true,
                              "Friday": true,
                              "Saturday": true,
                              "Sunday": true,
                              "createdAt": "2022-10-03T22:39:50.516Z",
                              "updatedAt": "2022-10-03T22:39:50.516Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "booking_divings": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "uid": "string",
                              "createdAt": "2022-10-03T22:39:50.516Z",
                              "updatedAt": "2022-10-03T22:39:50.516Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "booking_tours": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "uid": "string",
                              "createdAt": "2022-10-03T22:39:50.516Z",
                              "updatedAt": "2022-10-03T22:39:50.516Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "createdAt": "2022-10-03T22:39:50.516Z",
                      "updatedAt": "2022-10-03T22:39:50.516Z",
                      "createdBy": {
                        "data": {
                          "id": 0,
                          "attributes": {}
                        }
                      },
                      "updatedBy": {
                        "data": {
                          "id": 0,
                          "attributes": {}
                        }
                      }
                    }
                  }
                },
                "isPrincipal": true,
                "carousel": {
                  "data": [
                    {
                      "id": 0,
                      "attributes": {
                        "name": "string",
                        "alternativeText": "string",
                        "caption": "string",
                        "width": 0,
                        "height": 0,
                        "formats": "string",
                        "hash": "string",
                        "ext": "string",
                        "mime": "string",
                        "size": 0,
                        "url": "string",
                        "previewUrl": "string",
                        "provider": "string",
                        "provider_metadata": "string",
                        "related": {
                          "data": [
                            {
                              "id": 0,
                              "attributes": {}
                            }
                          ]
                        },
                        "folder": {
                          "data": {
                            "id": 0,
                            "attributes": {
                              "name": "string",
                              "pathId": 0,
                              "parent": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "children": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {}
                                  }
                                ]
                              },
                              "files": {
                                "data": [
                                  {
                                    "id": 0,
                                    "attributes": {
                                      "name": "string",
                                      "alternativeText": "string",
                                      "caption": "string",
                                      "width": 0,
                                      "height": 0,
                                      "formats": "string",
                                      "hash": "string",
                                      "ext": "string",
                                      "mime": "string",
                                      "size": 0,
                                      "url": "string",
                                      "previewUrl": "string",
                                      "provider": "string",
                                      "provider_metadata": "string",
                                      "related": {
                                        "data": [
                                          {
                                            "id": 0,
                                            "attributes": {}
                                          }
                                        ]
                                      },
                                      "folder": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      },
                                      "folderPath": "string",
                                      "createdAt": "2022-10-03T22:39:50.516Z",
                                      "updatedAt": "2022-10-03T22:39:50.516Z",
                                      "createdBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {
                                            "firstname": "string",
                                            "lastname": "string",
                                            "username": "string",
                                            "email": "user@example.com",
                                            "resetPasswordToken": "string",
                                            "registrationToken": "string",
                                            "isActive": true,
                                            "roles": {
                                              "data": [
                                                {
                                                  "id": 0,
                                                  "attributes": {
                                                    "name": "string",
                                                    "code": "string",
                                                    "description": "string",
                                                    "users": {
                                                      "data": [
                                                        {
                                                          "id": 0,
                                                          "attributes": {}
                                                        }
                                                      ]
                                                    },
                                                    "permissions": {
                                                      "data": [
                                                        {
                                                          "id": 0,
                                                          "attributes": {
                                                            "action": "string",
                                                            "subject": "string",
                                                            "properties": "string",
                                                            "conditions": "string",
                                                            "role": {
                                                              "data": {
                                                                "id": 0,
                                                                "attributes": {}
                                                              }
                                                            },
                                                            "createdAt": "2022-10-03T22:39:50.516Z",
                                                            "updatedAt": "2022-10-03T22:39:50.516Z",
                                                            "createdBy": {
                                                              "data": {
                                                                "id": 0,
                                                                "attributes": {}
                                                              }
                                                            },
                                                            "updatedBy": {
                                                              "data": {
                                                                "id": 0,
                                                                "attributes": {}
                                                              }
                                                            }
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    "createdAt": "2022-10-03T22:39:50.516Z",
                                                    "updatedAt": "2022-10-03T22:39:50.516Z",
                                                    "createdBy": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {}
                                                      }
                                                    },
                                                    "updatedBy": {
                                                      "data": {
                                                        "id": 0,
                                                        "attributes": {}
                                                      }
                                                    }
                                                  }
                                                }
                                              ]
                                            },
                                            "blocked": true,
                                            "preferedLanguage": "string",
                                            "createdAt": "2022-10-03T22:39:50.516Z",
                                            "updatedAt": "2022-10-03T22:39:50.516Z",
                                            "createdBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            },
                                            "updatedBy": {
                                              "data": {
                                                "id": 0,
                                                "attributes": {}
                                              }
                                            }
                                          }
                                        }
                                      },
                                      "updatedBy": {
                                        "data": {
                                          "id": 0,
                                          "attributes": {}
                                        }
                                      }
                                    }
                                  }
                                ]
                              },
                              "path": "string",
                              "createdAt": "2022-10-03T22:39:50.516Z",
                              "updatedAt": "2022-10-03T22:39:50.516Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        },
                        "folderPath": "string",
                        "createdAt": "2022-10-03T22:39:50.516Z",
                        "updatedAt": "2022-10-03T22:39:50.516Z",
                        "createdBy": {
                          "data": {
                            "id": 0,
                            "attributes": {}
                          }
                        },
                        "updatedBy": {
                          "data": {
                            "id": 0,
                            "attributes": {}
                          }
                        }
                      }
                    }
                  ]
                },
                "business_services": {
                  "data": [
                    {
                      "id": 0,
                      "attributes": {}
                    }
                  ]
                },
                "TypeBussines": "Alojamiento",
                "name": "string",
                "createdAt": "2022-10-03T22:39:50.516Z",
                "updatedAt": "2022-10-03T22:39:50.516Z",
                "createdBy": {
                  "data": {
                    "id": 0,
                    "attributes": {}
                  }
                },
                "updatedBy": {
                  "data": {
                    "id": 0,
                    "attributes": {}
                  }
                }
              }
            }
          ]
        },
        "payment_gateways": {
          "data": [
            {
              "id": 0,
              "attributes": {
                "name": "string",
                "tokenpublic": "string",
                "tokenprivate": "string",
                "commercename": "string",
                "state": true,
                "uid": "string",
                "createdAt": "2022-10-03T22:39:50.517Z",
                "updatedAt": "2022-10-03T22:39:50.517Z",
                "createdBy": {
                  "data": {
                    "id": 0,
                    "attributes": {}
                  }
                },
                "updatedBy": {
                  "data": {
                    "id": 0,
                    "attributes": {}
                  }
                }
              }
            }
          ]
        },
        "users_permissions_users": {
          "data": [
            {
              "id": 0,
              "attributes": {
                "username": "string",
                "email": "user@example.com",
                "provider": "string",
                "resetPasswordToken": "string",
                "confirmationToken": "string",
                "confirmed": true,
                "blocked": true,
                "role": {
                  "data": {
                    "id": 0,
                    "attributes": {
                      "name": "string",
                      "description": "string",
                      "type": "string",
                      "permissions": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {
                              "action": "string",
                              "role": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "createdAt": "2022-10-03T22:39:50.517Z",
                              "updatedAt": "2022-10-03T22:39:50.517Z",
                              "createdBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              },
                              "updatedBy": {
                                "data": {
                                  "id": 0,
                                  "attributes": {}
                                }
                              }
                            }
                          }
                        ]
                      },
                      "users": {
                        "data": [
                          {
                            "id": 0,
                            "attributes": {}
                          }
                        ]
                      },
                      "createdAt": "2022-10-03T22:39:50.517Z",
                      "updatedAt": "2022-10-03T22:39:50.517Z",
                      "createdBy": {
                        "data": {
                          "id": 0,
                          "attributes": {}
                        }
                      },
                      "updatedBy": {
                        "data": {
                          "id": 0,
                          "attributes": {}
                        }
                      }
                    }
                  }
                },
                "createdAt": "2022-10-03T22:39:50.517Z",
                "updatedAt": "2022-10-03T22:39:50.517Z",
                "createdBy": {
                  "data": {
                    "id": 0,
                    "attributes": {}
                  }
                },
                "updatedBy": {
                  "data": {
                    "id": 0,
                    "attributes": {}
                  }
                }
              }
            }
          ]
        },
        "legalRepresentant": "string",
        "legalAddress": "string",
        "legalEmail": "user@example.com",
        "legalPhone": "string",
        "createdAt": "2022-10-03T22:39:50.517Z",
        "updatedAt": "2022-10-03T22:39:50.517Z",
        "createdBy": {
          "data": {
            "id": 0,
            "attributes": {}
          }
        },
        "updatedBy": {
          "data": {
            "id": 0,
            "attributes": {}
          }
        }
      }
    },
    "meta": {}
}
export const companySlice = createSlice({
    name: "company",
    initialState: {
      value: companyDefault,
    },
    reducers: {
      setCompany: (state, action) => action.payload,
      resetCompany: () => companyDefault,
    },
});  

export default companySlice.reducer;
export const { setCompany, resetCompany } = companySlice.actions;

