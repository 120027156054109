import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import logo1 from "../../assets/img/crecimiento-del-dinero.png";
import reserva from "../../assets/img/reserva.png";
import website from "../../assets/img/web-site.png";
import "./ComponentsHome.css";
import Loading from "../../ui/Loading/Loading";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function ComponentsHome() {
  const navigate = useNavigate();
  const [bussinesHotel, setBussinesHotel] = useState(false);
  const [bussinesRestaurant, setBussinesRestaurant] = useState(false);
  const [bussinesDiving, setBussinesDiving] = useState(false);
  const [bussinesTour, setBussinesTour] = useState(false);
  const [id, setId] = useState(1);

  const [company, setCompanyByIdArray] = useState([1]);
  const [bussinesHotelId, setBussinesHotelId] = useState(null);
  const [bussinesRestaurantId, setBussinesRestaurantId] = useState(null);
  const [bussinesDivingId, setBussinesDivingId] = useState(null);
  const [bussinesTourId, setBussinesTourId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const companySession = JSON.parse(localStorage.getItem("MSCOMPANY"));
    setCompanyByIdArray(companySession);
    companySession.attributes.businesses.data.map((itemComponent) => {
      switch (itemComponent.attributes.TypeBussines) {
        case "Alojamiento":
          setBussinesHotel(true);
          setBussinesHotelId(itemComponent.id);
          break;
        case "Restaurante":
          setBussinesRestaurant(true);
          setBussinesRestaurantId(itemComponent.id);
          break;
        case "Buceo":
          setBussinesDiving(true);
          setBussinesDivingId(itemComponent.id);
          break;
        case "Toures":
          setBussinesTour(true);
          setBussinesTourId(itemComponent.id);
          break;
        default:
          break;
      }
    });
  }, []);

  const activateBooking = () => {
    navigate("/ConfiguracionReservasActivas");
  };

  if (company === null) {
    dispatch(showLoading(true));
  } else {
    return (
      <>
        <Container style={{ minHeight: "150%" }}>
          <Row>
            <Col md={4} sm={12} pb={5}>
              <Card className="CardS card1">
                <Card.Img
                  variant="top"
                  className="img-logo"
                  src={website}
                  style={{ paddingTop: "20px" }}
                />
                <Card.Body>
                  <Card.Title className="title">
                    Configura tu sistema de reservas
                  </Card.Title>
                  <Card.Text className="text">
                    Ingresa a tus reservas e identifica tus clientes
                  </Card.Text>
                  <Stack gap={2} className="col-md-5 mx-auto ">
                    {bussinesRestaurant && (
                      <Link
                        className="btn btn-danger btn1"
                        to={`/ConfiguracionesReservaRestaurante/${bussinesRestaurantId}`}
                      >
                        Restaurante
                      </Link>
                    )}
                    {bussinesHotel && (
                      <Link
                        className="btn btn-danger btn1"
                        to={`/ConfiguracionesReservaHospedaje/${bussinesHotelId}`}
                      >
                        Alojamiento
                      </Link>
                    )}{" "}
                    {bussinesTour && (
                      <Link
                        className="btn btn-danger btn1"
                        to={`/ConfiguracionesReservaToures/${bussinesTourId}`}
                      >
                        Toures
                      </Link>
                    )}
                    {bussinesDiving && (
                      <Link
                        className="btn btn-danger btn1"
                        to={`/ConfiguracionesReservaBuceo/${bussinesDivingId}`}
                      >
                        Buceo
                      </Link>
                    )}
                  </Stack>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card className="CardS card2">
                <Card.Img
                  variant="top"
                  className="img-logo"
                  src={reserva}
                  style={{ paddingTop: "20px" }}
                />
                <Card.Body>
                  <Card.Title className="title">Reservas activas</Card.Title>
                  <Card.Text className="text">
                    Ingresa a tus reservas e identifica tus clientes
                  </Card.Text>
                  <Button variant="danger" onClick={activateBooking}>
                    Ver reservas
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card className="CardS card3">
                <Card.Img
                  variant="top"
                  className="img-logo"
                  src={logo1}
                  style={{ paddingTop: "20px" }}
                />
                <Card.Body>
                  <Card.Title className="title">
                    Tus ventas y ganancias
                  </Card.Title>
                  <Card.Text className="text">
                    Toda tu informacion de cuantos has vendido
                  </Card.Text>
                  <Button variant="danger">Ver reservas</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default ComponentsHome;
