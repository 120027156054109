import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import userReducer from "./slices/user";
import companyReducer from './slices/company'
import loadingSliceReducer from './slices/Loader/loadingReducer'

const reducers = combineReducers({
  user: userReducer,
  company: companyReducer,
  showLoading: loadingSliceReducer,
});

const store = configureStore({
  reducer: reducers,
});

export default store;
