import React from 'react';
import ConfiguracionMediosDePago from '../components/MediosDePago/ConfiguracionMediosDePago';
import NavBarApplication from "../ui/Navbar/NavBarApplication";

function MediosdePago() {
  return (
    <>
    <NavBarApplication></NavBarApplication>
    <ConfiguracionMediosDePago></ConfiguracionMediosDePago>
    
    </>
  )
}

export default MediosdePago