import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AddNewTable.css";
import { addBookingRestaurant } from "msbookingapiclient";
import Swal from "sweetalert2";
//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function AddNewTable(props) {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [newRestaurantBooking, setNewRestaurantBooking] = useState({
    capacity: 0,
    status: true,
  });

  const saveBooking = () => {
    Swal.fire({
      title: "Esta seguro de crear una nueva mesa?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Crear mesa",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true));
        addBookingRestaurant(props.booking_Id, newRestaurantBooking)
          .then((result) => {
            props.bookingList.push(newRestaurantBooking);
            dispatch(showLoading(false));
            Swal.fire("Mesa creada correctamente");
            props.onShow(false);
          })
          .catch((error) => {
            Swal.fire("La mesa no se ha creado correctamente");
            dispatch(showLoading(false));
            props.onShow(false);
          });
      } else {
        return;
      }
    });
  };
  const countItem = (quantity) => {
    setCount(quantity);
    newRestaurantBooking.capacity = quantity;
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onShow(false)}
        dialogClassName="modal-90w "
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>Añadir mesa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Capacidad:</label>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => countItem(count + 1)}
          >
            +
          </Button>

          <label name="capacity" style={{ marginLeft: "10px" }}>
            {count}
          </label>
          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => countItem(count - 1)}
          >
            -
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveBooking} variant="primary">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewTable;
