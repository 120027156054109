import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Menu.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.jpeg";
import Swal from "sweetalert2";
import Loading from "../Loading/Loading";

//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function NavBarApplication(props) {
  const [bussinesHotel, setBussinesHotel] = useState(false);
  const [bussinesRestaurant, setBussinesRestaurant] = useState(false);
  const [bussinesDiving, setBussinesDiving] = useState(false);
  const [bussinesTour, setBussinesTour] = useState(false);

  const [bussinesHotelId, setBussinesHotelId] = useState(null);
  const [bussinesRestaurantId, setBussinesRestaurantId] = useState(null);
  const [bussinesDivingId, setBussinesDivingId] = useState(null);
  const [bussinesTouresId, setBussinesTouresId] = useState(null);
  const [logOut, setLogOut] = useState(props.setUserState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const companySession = JSON.parse(localStorage.getItem("MSCOMPANY"));
    companySession.attributes.businesses.data.map((itemOption) => {
      switch (itemOption.attributes.TypeBussines) {
        case "Alojamiento":
          setBussinesHotel(true);
          setBussinesHotelId(itemOption.id);
          break;
        case "Restaurante":
          setBussinesRestaurant(true);
          setBussinesRestaurantId(itemOption.id);
          break;
        case "Buceo":
          setBussinesDiving(true);
          setBussinesDivingId(itemOption.id);
          break;
        case "Toures":
          setBussinesTour(true);
          setBussinesTouresId(itemOption.id);
          break;
        default:
          break;
      }
    });
  }, []);

  const cerrarSession = () => {
    Swal.fire({
      title: "¿Desea salir de la aplicación?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(showLoading(true));
        sessionStorage.clear();
        localStorage.clear();
        setLogOut(null);
        dispatch(showLoading(false));
        navigate("/Login");
      } else if (result.isDenied) {
        return;
      }
    });
  };


  const Home = () =>{
    navigate("/");
  }
  if (loading) {
    <Loading />

  } else {
    return (
      <>
        {[false].map((expand) => (
          <Navbar key={expand} expand={expand} className="mb1">
            <Container fluid>
              <Navbar.Brand className="Title">
                <a onClick={Home}>
                  <img
                    alt=""
                    src={logo}
                    width="100"
                    height="30"
                    className="d-inline-block align-top img "
                  />
                </a>
                Tu aplicación de reservas
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img
                      alt=""
                      src={logo}
                      width="100"
                      height="30"
                      className="d-inline-block align-top img "
                    />
                    Menú de opciones
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="bg-dark">
                  <Nav>
                    <ul>
                      <li className="Ullink">
                        <Link className="links" to="/">
                          <i className="bi bi-house"></i>
                          Inicio
                        </Link>
                      </li>
                      <li className="Ullink">
                        {bussinesHotel && (
                          <Link
                            className="links"
                            to={`/ConfiguracionesReservaHospedaje/${bussinesHotelId}`}
                          >
                            <i className="bi bi-building"></i>
                            Confi Hospedaje
                          </Link>
                        )}
                      </li>
                      <li className="Ullink">
                        {bussinesRestaurant && (
                          <Link
                            className="links"
                            to={`/ConfiguracionesReservaRestaurante/${bussinesRestaurantId}`}
                          >
                            <i className=" bi bi-shop"></i>
                            Confi Restaurante
                          </Link>
                        )}
                      </li>
                      <li className="Ullink">
                        {bussinesTour && (
                          <Link
                            className="links"
                            to={`/ConfiguracionesReservaToures/${bussinesTouresId}`}
                          >
                            <i className=" bi bi-geo-alt"></i>
                            Confi Toures
                          </Link>
                        )}
                      </li>
                      <li className="Ullink">
                        {bussinesDiving && (
                          <Link
                            className="links"
                            to={`/ConfiguracionesReservaBuceo/${bussinesDivingId}`}
                          >
                            <i className="bi bi-water"></i>
                            Confi Buceo
                          </Link>
                        )}
                      </li>
                      <li className="Ullink">
                        <Link className="links " to="/MediosdePago">
                          <i className="bi bi-coin"></i>
                          Medios de Pago
                        </Link>
                      </li>
                      {/* <li className="Ullink">
                      <Link className="links " to="/TusComunicaciones">
                        <i className="bi bi-coin"></i>
                        Tus Comunicaciones
                      </Link>
                    </li> */}

                      <li className="Ullink">
                        <Link className="links " to={`/ConfiguracionReservasActivas`}>
                          <i className="bi bi-coin"></i>
                          Tus reservas activas
                        </Link>
                      </li>
                      {/* <li className="Ullink">
                      <Link className="links " to="/TusIngresos">
                        <i className="bi bi-coin"></i>
                        Tus ingresos
                      </Link>
                    </li> */}
                      <li className="Ullink">
                        <Button className="" onClick={cerrarSession}>
                          <i className="bi bi-x-circle-fill"></i> Cerrar Sesion{" "}
                        </Button>
                      </li>
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    );
  }
}

export default NavBarApplication;
