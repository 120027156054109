import React, { useState, useEffect } from "react";
import { Carousel, Container } from "react-bootstrap";
import coverDefault from "../../assets/img/coverDefault.png";
import AddImageServices from "../AddImageServices/AddImageServices";
import "./carouselBanner.css";

function CarouselBanner(props) {
  const [newArrayMicrosite, setNewArrayMicrosite] = useState([]);
  //cargar la imagenes
  //si no llega imagenes
  if (props.carousel.data === null) {
    props.carousel.data = [coverDefault];
  }

  useEffect(() => {
    props.carousel.data.map((imagen, index) => {
      let imagenNew = {
        id: imagen.id,
        url: imagen.attributes.url,
        name: imagen.attributes.name,
      };
      newArrayMicrosite.push(imagenNew);
    });
  }, []);
  return (
    <div className="carouselBanner">
      <Carousel className="carouselBorder">
        {props.carousel.data.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              height={"400px"}
              className="d-block w-100"
              src={item.attributes.url}
              alt={item.attributes.name}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <AddImageServices carousel={newArrayMicrosite}></AddImageServices>
    </div>
  );
}
export default CarouselBanner;
