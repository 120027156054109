import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./RoomServices.css";
import CardRooms from "./CardRooms";
import {
  getBookingById,
  deleteBookingHotel,
  cloneBookingHotel,
  getBookingHotelById,
} from "msbookingapiclient";
import EditRoom from "./EditRoom";
import { Card, Button } from "react-bootstrap";
import AddNewRoom from "../NewRoom/AddNewRoom";
import Swal from "sweetalert2";


//redux>
import { useDispatch } from "react-redux";
import { showLoading } from "../../store/slices/Loader/loadingReducer";
//redux<

function BookingHotelList(props) {
  const dispatch = useDispatch();
  const [itemData, setItemData] = useState({});
  const [showItem, setShowItem] = useState(false);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [showItemEdit, setShowItemEdit] = useState(false);
  const [idRooms, setIdItem] = useState(1);
  const [bookingItem, setBookingItem] = useState({
    id: 0,
    booking_hotels: [],
  });

  useEffect(() => {
    dispatch(showLoading(true))
    getBookingById(props.booking_Id)
      .then((result) => {
        const filterList = result.data.booking_hotels.filter(
          (item) => item.status === true
        );
        result.data.booking_hotels = filterList;
        setBookingItem(result.data);
        dispatch(showLoading(false))
      })
      .catch((error) => {
        dispatch(showLoading(false))
        console.log(error);
      });
  }, [props.booking_Id]);

  const showItemDetailsListener = (stateBoolean, index) => {
    setItemData(bookingItem.booking_hotels[index]);
    setShowItemDetails(stateBoolean);
  };
  const closeItemDetailsListener = (onShow) => {
    setShowItemDetails(onShow);
  };
  const showItemEditState = (stateBoolean, index) => {
    setItemData(bookingItem.booking_hotels[index]);
    setShowItemEdit(stateBoolean);
  };

  const closeItemEditListener = (onShow) => {
    setShowItemEdit(onShow);
  };

  const showNewItem = () => {
    setShowItem(true);
  };

  const closeNewItem = () => {
    setShowItem(false);
  };

  const Clone = (id) => {
    Swal.fire({
      title: "¿Seguro de clonar este servicio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Clonar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        cloneBookingHotel(props.booking_Id, id).then((result) => {
          let newSlot = result;
          newSlot.attributes.id = result.id;
          bookingItem.booking_hotels.push(newSlot.attributes);
          dispatch(showLoading(false))
        });
      }
      return;
    });
  };
  // 1 enviar el id del booking
  // 2filtrar por el estado del booking
  const Delete = (id) => {
    Swal.fire({
      title: "¿Seguro de borrar este servicio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(showLoading(true))
        deleteBookingHotel(id).then((result) => {
          const index = bookingItem.booking_hotels.findIndex(
            (item) => item.id === result.data.data.id
          );
          bookingItem.booking_hotels.slice(index, 1);
          dispatch(showLoading(false))
        });
      }
      return;
    });
  };
  let rooms = bookingItem.booking_hotels.map((item, index) => {
    return (
      <div key={index}>
        <Card key="" style={{ width: "97%" }} className="mb-2 cardRoomm">
          <Card.Header>
            <div className="ToolBox">
              <Button
                onClick={() => showItemEditState(true, index)}
                className="bg-primary"
                size="sm"
              >
                <i className="bi bi-pencil-square"></i>Editar
              </Button>
              <Button
                className="bg-primary"
                onClick={() => {
                  Clone(item.id);
                }}
                size="sm"
              >
                <i className="bi bi-clipboard-check-fill"></i>Clonar
              </Button>
              <Button
                onClick={() => {
                  Delete(item.id);
                }}
                className="bg-primary"
                size="sm"
              >
                <i className="bi bi-trash3"></i>Borrar
              </Button>
            </div>
          </Card.Header>
          <div className="FormCard">
            <Card.Body>
              <CardRooms room={item}></CardRooms>
            </Card.Body>
          </div>
        </Card>
      </div>
    );
  });

  return (
    <Row>
      <h2>Configuracion de Booking</h2>
      <Col xl="12">{rooms}</Col>
      <AddNewRoom
        show={showItem}
        onShow={setShowItem}
        booking_Id={props.booking_Id}
        bookingList={bookingItem.booking_hotels}
      ></AddNewRoom>
      <EditRoom
        room={itemData}
        show={showItemEdit}
        onShow={closeItemEditListener}
      ></EditRoom>
      <Button onClick={showNewItem} className="mx-auto mb-2 w-auto">
        Añadir Habitacion
      </Button>
    </Row>
  );
}

export default BookingHotelList;
